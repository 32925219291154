'use client';

import { Box, Flex, FlexProps, Heading, Skeleton, Text } from '@chakra-ui/react';
import { formatUsd, IconArrowDown, IconArrowUp, uiColors } from '@cryptofi/core-ui';

interface Props extends FlexProps {
  isLoading?: boolean;
  amount?: number;
  heading: string;
  isNetGain: boolean;
}

const TrendIndicator = ({ isLoading, amount = 0, heading, isNetGain = true, ...rest }: Props) => {
  return (
    <Flex alignItems="center" gap="2" minW="8rem" {...rest}>
      <Skeleton width="full" isLoaded={!isLoading}>
        <Box bg={isNetGain ? uiColors.aijiroWhite() : uiColors.redRemains()} p="1" borderRadius="sm" mr="2">
          {isNetGain && <IconArrowUp __css={{ path: { fill: uiColors.mint() } }} />}

          {!isNetGain && <IconArrowDown __css={{ path: { fill: uiColors.heroicRed() } }} />}
        </Box>

        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
          gap={{ base: 0, lg: 2 }}
          alignItems={{ base: 'flex-start', lg: 'center' }}
        >
          <Heading as="h2" size="xs">
            {heading}
          </Heading>

          {Number.isFinite(amount) && <Text fontFamily="heading">{formatUsd({ amount })}</Text>}
        </Flex>
      </Skeleton>
    </Flex>
  );
};

export default TrendIndicator;
