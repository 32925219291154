import { Box, Button as ChakraButton, Portal, Tooltip } from '@chakra-ui/react';

const ConsoleButton = ({
  isOpen,
  enableRefetch,
  onOpen,
}: {
  isOpen: boolean;
  enableRefetch: boolean;
  onOpen: () => void;
}) => {
  return (
    <Portal>
      {!isOpen && (
        <Box
          position="fixed"
          top="4"
          right="4"
          zIndex="1000"
          bgColor="blackAlpha.800"
          borderRadius="md"
          transition="background-color 0.25s"
          className="dev-console"
          _hover={{ bgColor: 'blackAlpha.900' }}
        >
          {!enableRefetch && (
            <Box
              bg="purple.500"
              borderRadius="full"
              width="3"
              height="3"
              position="absolute"
              top="-0.25rem"
              right="-0.25rem"
              zIndex="1"
            />
          )}

          <Tooltip label={enableRefetch ? 'Dev console (ctrl+d)' : 'Refetch intervals disabled'} hasArrow>
            <ChakraButton
              aria-label="Open"
              size="sm"
              onClick={onOpen}
              fontSize="lg"
              bg="transparent !important"
              color="green.500"
              padding="0"
              boxShadow="dark-lg"
            >
              ☆
            </ChakraButton>
          </Tooltip>
        </Box>
      )}
    </Portal>
  );
};

export default ConsoleButton;
