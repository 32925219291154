'use client';
import { Flex, Td, Text, Tr } from '@chakra-ui/react';
import { formatPercentage, formatUsd, getDelta, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';

import { AssetLogo } from '~/components';
import { AllAssetIds } from '~/customTypes';
import { uiConstants } from '~/theme';

export default function AssetListItem({
  assetId,
  previousClosingPrice = '0',
  buyPrice = '0',
  onAssetClick,
  isLastRow,
  children,
}: {
  assetId: AllAssetIds;
  assetName?: string;
  previousClosingPrice?: string;
  buyPrice?: string;
  onAssetClick: () => void;
  isLastRow: boolean;
  children?: React.ReactNode;
}) {
  const { isLargeBreakpoint } = useCfBreakpoint();
  const applyBorderRadius = isLastRow && !isLargeBreakpoint;

  const { isNetGain, percentChange } = getDelta({
    data: [{ value: previousClosingPrice }, { value: buyPrice }],
    dataKey: 'value',
  });

  return (
    <Tr
      onClick={onAssetClick}
      cursor="pointer"
      backgroundColor="transparent"
      transition={`background-color ${uiConstants.transitionDuration}`}
      _hover={{
        backgroundColor: uiConstants.hoverBackground,
      }}
    >
      <Td p="0" pl="4" borderBottomLeftRadius={applyBorderRadius ? 'xl' : 'none'}>
        {assetId && <AssetLogo assetId={assetId} showName showId maxW="12rem" />}
      </Td>

      {/* height must be set on this table cell for charts to render */}
      {/* maxW must match CfLineChart */}
      <Td p="2" minW={{ md: '8rem' }} maxW="12rem" height="4.5rem">
        {children}
      </Td>

      <Td
        textAlign="right"
        fontFamily="heading"
        p="0"
        pr="4"
        borderBottomRightRadius={applyBorderRadius ? 'xl' : 'none'}
      >
        <Flex flexDir="column" width="24" ml="auto">
          <Text>
            {formatUsd({
              amount: buyPrice,
              autoPrecision: true,
            })}
          </Text>

          <Text fontSize="sm" color={isNetGain ? uiColors.mint() : uiColors.heroicRed()}>
            {percentChange && formatPercentage({ number: percentChange, isDecimal: false })}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}
