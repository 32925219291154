'use client';

import { Button, Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import { IconCaretRight, uiColors } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';

import { TransactionAccordion } from '~/components';
import { useGetUserOrdersSearch } from '~/hooks';
import { uiRoutes } from '~/routes';

dayjs.extend(utc);
dayjs.extend(timezone);

const LatestTrade = ({ ...rest }) => {
  const { data, isLoading } = useGetUserOrdersSearch({});

  const latestTransaction = data?.items?.length ? data?.items[0] : undefined;

  return (
    <Flex direction="column" gap="6" {...rest}>
      <Flex display="flex" justifyContent="space-between" alignItems="center">
        <Heading as="h2" size="md" borderColor={uiColors.coolElegance()} py="4">
          Latest trade
        </Heading>

        <Button as={NextLink} variant="ghost" rightIcon={<IconCaretRight />} href={uiRoutes.transactionHistory()}>
          View all
        </Button>
      </Flex>

      <Text>
        Track your recent activity with ease. This section provides a snapshot of your most recent transaction.
      </Text>

      <Skeleton isLoaded={!isLoading}>
        {latestTransaction ? (
          <TransactionAccordion transaction={latestTransaction} allowToggle={false} />
        ) : (
          <Flex justifyContent="center" border="1px dashed" borderColor={uiColors.coolElegance()} borderRadius="16">
            <Text px="6" py="4">
              Place your first trade to see it here!
            </Text>
          </Flex>
        )}
      </Skeleton>
    </Flex>
  );
};

export default LatestTrade;
