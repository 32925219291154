import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { User } from '~/codegen/types';
import { Product } from '~/customTypes';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

const usePostTerms = ({
  options,
  selectedProducts,
}: {
  options?: UseMutationOptions<Camelized<User>, void, void>;
  selectedProducts: Product[];
}) => {
  const { apiClient } = useAxiosContext();
  // product of undefined will make the request for all products
  const product = selectedProducts.length === 1 ? selectedProducts[0] : undefined;
  return useMutation({
    mutationFn: async () => {
      return apiClient!.post(apiRoutes.terms(product)).then((r) => {
        const data = camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        }) as Camelized<User>;

        return data; // this response is currently unused
      });
    },
    ...options,
  });
};

export default usePostTerms;
