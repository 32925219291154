import { IconCircleCheck, IconDollarBill, IconLock } from '@cryptofi/core-ui';

export const introContentsCrypto = [
  {
    title: 'Safe, secure, and insured',
    description:
      'Trade cryptocurrencies with the confidence that your holdings are secure and insured against hacking or theft.',
    Icon: IconLock,
  },
  {
    title: 'Easy and convenient',
    description:
      'Trading cryptocurrencies with us is easy. Simply choose a coin, choose an account to use, and trade - no additional setup required.',
    Icon: IconCircleCheck,
  },
  {
    title: 'Instant cash from sell orders',
    description: 'Money earned from selling crypto is available to spend immediately.',
    Icon: IconDollarBill,
  },
];

export const introContentsSecurities = [
  {
    description: 'Trade directly from your bank account. No painful linking to external accounts.',
  },
  {
    description: 'Keep your investment portfolio under one account.',
  },
  {
    description: 'Instant cash from sell orders, deposited directly in your account.',
  },
  {
    description: 'Explore our robust educational content to expand your investing prowess with confidence.',
  },
  {
    description: 'Skip the high trading fees from other companies like Fidelity and Schwab.',
  },
];
