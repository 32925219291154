'use client';

import { ButtonGroupProps } from '@chakra-ui/react';
import { CfButtonGroup, CfButtonGroupButton } from '@cryptofi/core-ui';
import { Dispatch, SetStateAction } from 'react';

import { ChartTimeRange } from '~/customTypes';

interface Props extends ButtonGroupProps {
  selectedTimeRange: ChartTimeRange;
  setSelectedTimeRange: Dispatch<SetStateAction<ChartTimeRange>>;
}

const TimeRangeSelector = ({ selectedTimeRange, setSelectedTimeRange, ...rest }: Props) => {
  return (
    <CfButtonGroup {...rest}>
      {[
        // { range: 'pastHour', text: '1H' },
        { range: 'pastDay', text: '1D' },
        { range: 'pastWeek', text: '1W' },
        { range: 'pastMonth', text: '1M' },
        { range: 'pastYear', text: '1Y' },
        { range: 'allTime', text: 'All' },
      ].map(({ range, text }) => {
        return (
          <CfButtonGroupButton
            isSelected={selectedTimeRange === range}
            key={range}
            onClick={() => setSelectedTimeRange(range as ChartTimeRange)}
          >
            {text}
          </CfButtonGroupButton>
        );
      })}
    </CfButtonGroup>
  );
};

export default TimeRangeSelector;
