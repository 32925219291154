import { usdToNumber } from '@cryptofi/core-ui';
import { UseMutationResult } from '@tanstack/react-query';
import Big from 'big.js';
import { Camelized } from 'humps';
import { FieldValues, UseFormGetFieldState, UseFormGetValues } from 'react-hook-form';

import { OpenSearchOrder, OrderStatusEnum } from '~/codegen/types';
import { AllAssetIds, TransactionCurrencies, TransactionTypes, UserBalance } from '~/customTypes';
import { isCrypto } from '~/utils';

// TODO add tests

/**
 *
 * @description Validate the active form based on the transaction type. RHF formState.isValid is not
 * reliable in the context of the buy / sell forms. It remains false when there are no errors on any fields.
 */
export const isFormValid = ({
  getValues,
  getFieldState,
  transactionType,
}: {
  getValues: UseFormGetValues<FieldValues>;
  getFieldState: UseFormGetFieldState<FieldValues>;
  transactionType: TransactionTypes;
}) => {
  const values = getValues();
  const fieldNames = Object.keys(values).filter((k) => k.startsWith(transactionType)); // get all buy or sell fields by prefix
  const isFieldValid = (name: string) => {
    // check valid state of any fields with a value
    return !getFieldState(name).invalid && values[name] !== undefined && values[name] !== '';
  };

  return fieldNames.every((name) => isFieldValid(name));
};

/**
 *
 * @description Watch currency input for changes and convert value to a number. Incoming value is a
 * USD formatted string when currency is USD, and a stringified number when currency is an asset.
 */
export const getWatchedCurrencyAmount = ({
  watch,
  currency,
  name,
}: {
  watch: (name: string) => void;
  currency: TransactionCurrencies;
  name: string;
}) => {
  const rawCurrencyAmount = watch(name);
  const currencyAmount =
    currency === 'USD' ? usdToNumber({ usd: String(rawCurrencyAmount), precision: 8 }) : rawCurrencyAmount;

  return Number(currencyAmount);
};

/**
 *
 * @description Get the quantity of the asset to trade based on the currency amount and price.
 */
export const getTransactionQuantity = ({
  currency,
  currencyAmount,
  price,
}: {
  currency: TransactionCurrencies;
  currencyAmount: number;
  price: number;
}) => {
  if (isCrypto(currency)) {
    return currencyAmount;
  }

  if (price === 0 || currencyAmount === 0 || currency !== 'USD') {
    return 0;
  }

  return Big(currencyAmount).div(price).toNumber();
};

/**
 *
 * @description Get the sell quantity and handle the max sell option. Sell quantity is calculated
 * from user input unless they select max sell, then amountAvailable from the API is used instead
 * due to discrepancies with the calculated amount at higher decimal places.
 */
export const getSellQuantity = ({
  isMaxSell,
  amountAvailableAsset,
  amountAvailableUsd,
  sellCurrency,
  sellCurrencyAmount,
  sellPrice,
}: {
  isMaxSell: boolean;
  amountAvailableAsset: number;
  amountAvailableUsd: number;
  sellCurrency: TransactionCurrencies;
  sellCurrencyAmount: number;
  sellPrice: number;
}) => {
  if (isNaN(sellCurrencyAmount)) {
    return 0;
  }

  if (isMaxSell) {
    return sellCurrency === 'USD' ? amountAvailableAsset : amountAvailableUsd;
  }

  // not max sell, calculate from user input
  return Big(
    getTransactionQuantity({
      currency: sellCurrency,
      currencyAmount: sellCurrencyAmount,
      price: sellPrice,
    }),
  )
    .round(8)
    .toNumber();
};

/**
 *
 * @description Get the user's available balance for the selected asset. Returns
 * the asset quantity and USD amounts.
 */
export const getAvailableAmount = ({
  securityBalances,
  cryptoBalances,
  assetId,
  sellPrice,
}: {
  securityBalances?: UserBalance[];
  cryptoBalances?: UserBalance[];
  assetId: AllAssetIds;
  sellPrice: number;
}) => {
  const collection = isCrypto(assetId) ? cryptoBalances : securityBalances;
  const amountAvailableAsset = Number(collection?.find((balance) => balance.assetId === assetId)?.amountAvailable) || 0;
  const amountAvailableUsd = Big(amountAvailableAsset || 0)
    .mul(sellPrice)
    .toNumber();

  return {
    amountAvailableAsset,
    amountAvailableUsd,
  };
};

/**
 *
 * @description Get mutation state for the transaction based on the asset ID and transaction type.
 */
export const getTransactionState = ({
  assetId,
  transactionType,
  buyCrypto,
  sellCrypto,
  buySecurity,
  sellSecurity,
}: {
  assetId: AllAssetIds;
  transactionType: TransactionTypes;
  buyCrypto?: UseMutationResult<Camelized<OpenSearchOrder> | undefined, unknown, unknown, unknown>;
  sellCrypto?: UseMutationResult<Camelized<OpenSearchOrder> | undefined, unknown, unknown, unknown>;
  buySecurity?: UseMutationResult<Camelized<OpenSearchOrder> | undefined, unknown, unknown, unknown>;
  sellSecurity?: UseMutationResult<Camelized<OpenSearchOrder> | undefined, unknown, unknown, unknown>;
}) => {
  const isCryptoAsset = isCrypto(assetId);
  const isBuy = transactionType === 'buy';
  let transactionState;

  if (isBuy && isCryptoAsset) {
    transactionState = buyCrypto;
  }

  if (isBuy && !isCryptoAsset) {
    transactionState = buySecurity;
  }

  if (!isBuy && isCryptoAsset) {
    transactionState = sellCrypto;
  }

  if (!isBuy && !isCryptoAsset) {
    transactionState = sellSecurity;
  }

  return {
    transactionState,
    transactionStatusIsError: transactionState?.data?.status === OrderStatusEnum.ERROR,
  };
};
