import { Text } from '@chakra-ui/react';
import { formatUsd, uiColors } from '@cryptofi/core-ui';
import Big from 'big.js';

import { useModalContext } from './ModalContext';

// converts USD to selected asset or vice versa
const QuantityConversion = () => {
  const {
    buyCurrency,
    buyQuantity,
    buyPrice,
    sellCurrency,
    sellPrice,
    sellQuantity,
    transactionType,
    selectedAssetId,
  } = useModalContext();

  // buy
  if (transactionType === 'buy') {
    return (
      <Text fontSize="sm" position="absolute" top="0.5" right="0" color={uiColors.sonicSilver()}>
        {buyCurrency === 'USD' && `= ${buyQuantity.toFixed(8)} ${selectedAssetId}`}

        {buyCurrency !== 'USD' &&
          buyPrice &&
          buyQuantity &&
          `= ${formatUsd({
            amount: Big(buyPrice).mul(buyQuantity).toNumber(),
          })} USD`}
      </Text>
    );
  }

  // sell
  return (
    <Text fontSize="sm" position="absolute" top="0.5" right="0" color={uiColors.sonicSilver()}>
      {sellCurrency === 'USD' && `= ${sellQuantity} ${selectedAssetId}`}

      {sellCurrency !== 'USD' &&
        sellPrice &&
        sellQuantity &&
        `= ${formatUsd({
          amount: Big(sellPrice).mul(sellQuantity).toNumber(),
        })} USD`}
    </Text>
  );
};

export default QuantityConversion;
