import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';
import { map } from 'lodash';

import { TokenPriceResponse } from '~/codegen/types';
import { cryptoRefetchInterval } from '~/constants';
import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

export const tokenObjectToArray = (tokens: TokenPriceResponse) => map(tokens, (t) => t);

const useGetTokenPrices = (args?: { refetchInterval: number | false }) => {
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const { isEnabled } = useFeatureSetEnabled();
  const { apiClient } = useAxiosContext();
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const refetchInterval = args?.refetchInterval || cryptoRefetchInterval;
  const queryEnabled = Boolean(userAuthInfo) && isEnabled(['crypto']);

  const { data, isError } = useQuery({
    refetchInterval: disableRefetch ? false : refetchInterval,
    enabled: Boolean(userAuthInfo) && isEnabled(['crypto']),
    queryKey: [apiRoutes.price(), apiClient],
    queryFn: async () => {
      return apiClient!.get(apiRoutes.price()).then((r) => {
        const camelized = camelizeKeys(tokenObjectToArray(r.data)) as Camelized<TokenPriceResponse>[];

        return camelized.sort((a, b) => a?.baseToken.localeCompare(b?.baseToken));
      });
    },
  });

  // blocking request - omits isLoading and other unused properties
  return {
    data,
    isError,
    isEnabled: queryEnabled,
  };
};

export default useGetTokenPrices;
