import { Center, Flex, Text } from '@chakra-ui/react';
import { CfForm, CfInput, CfSelect, CfSpinner, formatUsd, uiColors } from '@cryptofi/core-ui';
import { debounce, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AllAssetIds } from '~/customTypes';
import { useLoadingMessages } from '~/hooks';
import { isCrypto } from '~/utils';

import { useModalContext } from './ModalContext';
import PresetAmounts from './PresetAmounts';
import QuantityConversion from './QuantityConversion';
import SelectAssetButton from './SelectAssetButton';
import ToggleCurrencyButton from './ToggleCurrencyButton';
import { TransactionFormValues } from './transactionSchema';

const BuyForm = ({ isLoading }: { isLoading: boolean }) => {
  const { register, formState, clearErrors, setValue, trigger, control, getValues, resetField } =
    useFormContext<TransactionFormValues>();
  const { setModalView, buyCurrency, setBuyCurrency, bankAccounts, selectedAssetId } = useModalContext();

  // reset account selection when the asset changes, to prevent a disabled account from being pre-selected
  useEffect(() => {
    resetField('buyAccountId');
  }, [selectedAssetId, resetField]);

  // select the change handler based on transaction currency
  // currency mode uses onValueChange instead of onChange
  // https://www.npmjs.com/package/react-currency-input-field#onvaluechange
  const changeHandler = () => {
    // prevent validation from being too aggressive on checking min amount
    const validateDebounced = debounce(() => {
      // skip validation if the field is empty or it will fail on non numeric input
      if (getValues('buyCurrencyAmount')) {
        trigger('buyCurrencyAmount');
      }
    }, 1000);

    if (buyCurrency === 'USD') {
      return {
        onValueChange: (value: string | undefined) => {
          // make it possible to clear the input
          if (value === undefined) {
            resetField('buyCurrencyAmount', { defaultValue: '' });
          }

          if (value) {
            // set the value and trigger validation
            setValue('buyCurrencyAmount', value);
            validateDebounced();

            // run balance check if an account is selected
            if (formState.touchedFields.buyAccountId) {
              trigger('buyAccountId');
            }
          }
        },
      };
    }

    return {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
          setValue('buyCurrencyAmount', e.target.value);
          validateDebounced();
        } else {
          resetField('buyCurrencyAmount', { defaultValue: '' });
        }
      },
    };
  };

  // TODO remove after bank accounts are populated by data provider CORE-844
  const { loadingMessage } = useLoadingMessages();

  return (
    <Flex flexDir="column" gap="6" w="full">
      {isLoading && (
        <>
          <Center mt="8">
            <Text color={uiColors.sonicSilver()} fontStyle="italic" fontSize="sm">
              {loadingMessage}
            </Text>
          </Center>

          <CfSpinner minH="auto" />
        </>
      )}

      {!isLoading && (
        <CfForm
          gap="0"
          id="buyForm"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // prevent enter key from navigating back to search
            }
          }}
          onSubmit={async () => {
            // validate currency amount again to handle edge cases due to debounce
            await trigger('buyCurrencyAmount');

            if (isEmpty(formState.errors)) {
              setModalView('reviewTransaction');
            }
          }}
        >
          <CfInput name="transactionType" register={register} defaultValue="buy" type="hidden" />

          {/* following hidden inputs contain derived values used for validation, and get populated via setValue */}
          <CfInput name="buyIsUsd" register={register} defaultValue="true" type="hidden" />

          <CfInput name="buyAvailableBalance" register={register} type="hidden" />

          <Flex gap="6" flexDirection="column" position="relative">
            {Boolean(getValues('buyCurrencyAmount')) && <QuantityConversion />}

            {/* this input is controlled in order to set its value programmatically in currency mode */}
            <Controller
              control={control}
              name="buyCurrencyAmount"
              render={({ field: { value } }) => {
                return (
                  <CfInput
                    name=""
                    value={value as string}
                    {...changeHandler()}
                    onFocus={() => {
                      // clear possible insufficient funds error when updating the amount
                      clearErrors('buyAccountId');
                    }}
                    errorMessage={formState.errors.buyCurrencyAmount?.message}
                    type={buyCurrency === 'USD' ? 'currency' : 'number'}
                    isRequired
                    label="Amount"
                    paddingRight="6em"
                    leftAddon={<SelectAssetButton selectedAssetId={selectedAssetId} setModalView={setModalView} />}
                    rightElement={
                      isCrypto(selectedAssetId) ? (
                        <ToggleCurrencyButton
                          currency={buyCurrency}
                          setCurrency={setBuyCurrency}
                          selectedAsset={selectedAssetId as AllAssetIds}
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                );
              }}
            />

            <PresetAmounts
              transactionType="buy"
              onClick={(e) => {
                const target = e.target as HTMLElement;

                // flip back to USD if necessary
                if (buyCurrency !== 'USD' && target.tagName === 'BUTTON') {
                  setBuyCurrency('USD');
                  setValue('buyIsUsd', true);
                }

                if (target.tagName === 'BUTTON') {
                  const button = target as HTMLButtonElement;

                  // set the buy amount and validate to update isValid
                  setValue('buyCurrencyAmount', button.value, { shouldValidate: true });

                  // run balance check on selected account
                  if (formState.touchedFields.buyAccountId === true) {
                    trigger('buyAccountId');
                  }
                }
              }}
            />

            <CfSelect
              name="buyAccountId"
              label="Pay with"
              isRequired
              register={register}
              errorMessage={formState.errors.buyAccountId?.message || formState.errors.buyAvailableBalance?.message}
              onChange={(e) => {
                const target = e.target as HTMLSelectElement;
                const { availableBalance } = bankAccounts.find(({ accountId }) => accountId === target.value)!;

                // set available balance for validation
                setValue('buyAvailableBalance', Number(availableBalance));

                setValue('buyAccountId', target.value, { shouldValidate: true });
              }}
              defaultValue=""
            >
              <option disabled value="">
                Select...
              </option>

              {bankAccounts.map(
                ({ accountId, displayAccountNumber, accountDescription, availableBalance, accountType }) => {
                  // only allow checking accounts for securities transactions
                  const isDisabled = !isCrypto(selectedAssetId) && accountType.toLowerCase() !== 'checking';

                  return (
                    <option
                      key={accountId}
                      value={accountId}
                      data-testid={`account-option-${displayAccountNumber}`}
                      disabled={isDisabled}
                    >
                      {/* eslint-disable-next-line react/jsx-newline */}
                      {accountDescription} **{displayAccountNumber} - {formatUsd({ amount: availableBalance })}
                    </option>
                  );
                },
              )}
            </CfSelect>
          </Flex>
        </CfForm>
      )}
    </Flex>
  );
};

export default BuyForm;
