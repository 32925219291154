import * as Sentry from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { User } from '~/codegen/types';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

const useGetUser = () => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();
  const queryEnabled = Boolean(userAuthInfo);

  const { data, isError, refetch, isRefetching } = useQuery({
    queryKey: [apiRoutes.user(), apiClient],
    enabled: queryEnabled,
    queryFn: async () => {
      return apiClient!.get(apiRoutes.user()).then((r) => {
        const camelized = camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as Camelized<User>;

        // identify user in Sentry
        Sentry.setUser({ id: camelized.userAccountId });

        return camelized;
      });
    },
  });

  // blocking request - omits isLoading and other unused properties
  return {
    data,
    isError,
    refetch,
    isRefetching,
    isEnabled: queryEnabled,
  };
};

export default useGetUser;
