import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { decamelizeKeys } from 'humps';

import { UserValues } from '~/codegen/types';
import { Product } from '~/customTypes';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

const usePostKyc = ({
  options,
  selectedProducts,
}: {
  options?: UseMutationOptions<void, AxiosError, UserValues>;
  selectedProducts: Product[];
}) => {
  const { apiClient } = useAxiosContext();
  // product of undefined will make the request for all products
  const product = selectedProducts.length === 1 ? selectedProducts[0] : undefined;
  return useMutation({
    mutationFn: async (body) => {
      return apiClient!.post(apiRoutes.kyc(product), decamelizeKeys(body));
    },
    ...options,
  });
};

export default usePostKyc;
