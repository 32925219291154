import { UseMutationResult } from '@tanstack/react-query';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import {
  AllAssetIds,
  BankAccount,
  SecurityAsset,
  Transaction,
  TransactionCurrencies,
  TransactionModalViews,
  TransactionTypes,
} from '~/customTypes';

type InvestModalContext = {
  // shared
  setModalView: Dispatch<SetStateAction<TransactionModalViews>>;
  transactionType: TransactionTypes;
  setTransactionType: Dispatch<SetStateAction<TransactionTypes>>;
  bankAccounts: BankAccount[];
  transactionState?: {
    isPending: boolean;
    isError: boolean;
    isSuccess: boolean;
  };
  transactionStatusIsError: boolean;
  selectedAssetId: AllAssetIds | undefined; // id of the asset the user has selected (or default)
  setSelectedAssetId: Dispatch<SetStateAction<AllAssetIds | undefined>>;
  selectedSecurity: SecurityAsset | undefined; // object representing the selected security

  // buy
  buyCurrency: TransactionCurrencies;
  setBuyCurrency: Dispatch<SetStateAction<TransactionCurrencies>>;
  buyPrice: number;
  buyCurrencyAmount: number;
  buyCrypto: UseMutationResult<Transaction | undefined, unknown, unknown, unknown>; // POST request / mutation for buying crypto
  buySecurity: UseMutationResult<Transaction | undefined, unknown, unknown, unknown>; // POST request / mutation for buying a security
  buyQuantity: number; // gross amount of crypto the user is purchasing
  netBuyQuantity: number; // net amount of crypto the user will receive after fees
  setNetBuyQuantity: Dispatch<SetStateAction<number>>;

  // sell
  amountAvailableUsd: number; // user's available balance in USD
  amountAvailableAsset: number; // user's available balance in terms of asset UOM (shares or crypto tokens)
  sellCurrency: TransactionCurrencies;
  setSellCurrency: Dispatch<SetStateAction<TransactionCurrencies>>;
  sellPrice: number;
  sellCurrencyAmount: number;
  sellCrypto: UseMutationResult<Transaction | undefined, unknown, unknown, unknown>;
  sellSecurity: UseMutationResult<Transaction | undefined, unknown, unknown, unknown>;
  sellQuantity: number;
  sellMaxTransactionLimit: number;
};

export const ModalContext = createContext({} as InvestModalContext);

export const useModalContext = () => useContext(ModalContext);
