import { Center, Text } from '@chakra-ui/react';
import { CfBreakpointDebugger, CfSpinner, layoutMinWidth } from '@cryptofi/core-ui';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import { DevConsole, LocalOnly } from '~/components';
import {
  useBalanceAndOrdersPolling,
  useGetFiInfo,
  useGetTokenPrices,
  useGetUser,
  useGetUserBankAccounts,
  useLoadingMessages,
} from '~/hooks';

const DataProvider = ({ children }: { children: React.ReactElement }) => {
  const user = useGetUser(); // onboarding info, user balances
  const fiInfo = useGetFiInfo(); // fi info, feature set
  const tokenPrices = useGetTokenPrices();

  const blockingRequests = [user, fiInfo, tokenPrices];
  useBalanceAndOrdersPolling();

  // non-blocking
  // TODO disable via isEnabled until onboarding is complete CORE-844
  useGetUserBankAccounts(); // gets fetched early to prime cache because slow to load, used in the invest modal

  const [showBreakpointDebugger, setShowBreakpointDebugger] = useState(false);

  const { loadingMessage } = useLoadingMessages();

  // TODO: add error logging CORE-844
  // https://github.com/vercel/next.js/issues/55462
  if (blockingRequests.some((request) => request.isError)) {
    return (
      <Center minHeight="100vh" flexDirection="column" gap="4" textAlign="center" minW={layoutMinWidth} p="6">
        <Text>Something went wrong. Please try again later.</Text>
      </Center>
    );
  }

  // some queries may be disabled based on enabled feature set
  if (blockingRequests.some((request) => request.isEnabled && isEmpty(request.data))) {
    return (
      <Center minHeight="100vh" flexDirection="column" gap="4" textAlign="center" minW={layoutMinWidth} p="6">
        <Text>{loadingMessage}</Text>

        <CfSpinner margin={5} boxSize="12" color="neutral" />
      </Center>
    );
  }

  return (
    <>
      {children}

      <LocalOnly>
        {!showBreakpointDebugger && <DevConsole setShowBreakpointDebugger={setShowBreakpointDebugger} />}

        {showBreakpointDebugger && (
          <CfBreakpointDebugger
            isCloseable
            onClick={() => {
              setShowBreakpointDebugger(false);
            }}
          />
        )}
      </LocalOnly>
    </>
  );
};

export { DataProvider };
