import { Button, Flex, Heading, Skeleton } from '@chakra-ui/react';
import { formatUsd } from '@cryptofi/core-ui';
import { UseQueryResult } from '@tanstack/react-query';
import { Camelized } from 'humps';

import { FeeBreakdown } from '~/codegen/types';
import { SystemStatusMessage } from '~/components';
import { BankAccount, TransactionTypes } from '~/customTypes';

const ConfirmTransactionButton = ({
  fees,
  confirmTransaction,
  isLoading,
  transactionType,
  isSystemDown,
  bankAccount,
}: {
  fees: UseQueryResult<Camelized<FeeBreakdown>, Error>;
  confirmTransaction: () => void;
  isLoading: boolean;
  transactionType: TransactionTypes;
  isSystemDown: boolean;
  bankAccount: BankAccount | undefined;
}) => {
  // accountType is not used directly here but is required by the confirmTransaction click handler
  // error gets caught by error boundary in the parent component
  if (!bankAccount?.accountType) {
    throw new Error('Account type is undefined');
  }

  return (
    <Flex flexDir="column" alignItems="flex-end" width="full">
      <Skeleton isLoaded={Boolean(fees?.data)}>
        <Heading as="h2" mt="2" size="md">
          Total:&nbsp;
          {formatUsd({
            amount: fees?.data?.txGross || 0,
          })}
        </Heading>
      </Skeleton>

      <SystemStatusMessage w="full" pt={2} textAlign="center" />

      <Button
        mt="6"
        w="full"
        isLoading={isLoading}
        isDisabled={!fees?.data || isSystemDown}
        onClick={confirmTransaction}
        loadingText="Processing transaction"
      >
        Confirm {transactionType === 'buy' ? 'purchase' : 'sale'}
      </Button>
    </Flex>
  );
};

export default ConfirmTransactionButton;
