/* eslint-disable no-restricted-syntax */
import { useEffect, useMemo, useState } from 'react';

// cycle through loading messages and stop on the last one
const useLoadingMessages = () => {
  const loadingMessages = useMemo(() => {
    return ['Getting your account balances...', 'Getting latest prices...', 'Just a few more seconds...'];
  }, []);

  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);
  const [, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => {
        if (prevIndex + 1 === loadingMessages.length) {
          clearInterval(interval);
          return prevIndex;
        }

        const newIndex = prevIndex + 1;
        setCurrentMessage(loadingMessages[newIndex]);
        return newIndex;
      });
    }, 3_000);

    return () => clearInterval(interval);
  }, [loadingMessages]);

  return {
    loadingMessage: currentMessage,
  };
};

export default useLoadingMessages;
