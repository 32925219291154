import * as Sentry from '@sentry/nextjs';

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const logError = ({ error, context }: { error: Error; context?: any }) => {
  Sentry.captureException(error, {
    extra: {
      ...context,
      inIframe: inIframe(),
    },
  });
};
