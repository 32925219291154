'use client';

import { Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import { CfTag, IconClose, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';
import { decamelize } from 'humps';

import { ActiveTxHistoryFilter } from '~/customTypes';

interface ActiveTxHistoryFilters extends FlexProps {
  activeFilters: ActiveTxHistoryFilter[];
  clearFilter: (config: { key?: string | undefined; all?: boolean | undefined }) => void;
  onOpen: () => void;
}

const ActiveFilters = ({ activeFilters, clearFilter, onOpen, ...rest }: ActiveTxHistoryFilters) => {
  const { isSmallBreakpoint, isMediumBreakpoint } = useCfBreakpoint();

  const renderActiveFilters = isMediumBreakpoint ? activeFilters.slice(0, 2) : activeFilters;

  if (isSmallBreakpoint) {
    return <></>;
  }

  return (
    <Flex w="full" gap={4} {...rest}>
      <Flex>Active filters</Flex>

      {activeFilters.length === 0 && <Text my={4}>No active filters</Text>}

      <Flex my={4} gap={4} wrap="wrap">
        {renderActiveFilters.map(([key, value]) => (
          <CfTag
            key={key}
            size="sm"
            py="2"
            px="4"
            label={`${decamelize(key as string, { separator: ' ' })}: ${value}`}
            rightIcon={IconClose}
            onClick={() => clearFilter({ key: key as string })}
            _hover={{
              path: {
                fill: uiColors.heroicRed(1),
              },
            }}
            textTransform="capitalize"
          />
        ))}

        {isMediumBreakpoint && activeFilters.length > 2 && (
          <Button variant="ghost" display={{ base: 'none', md: 'inline-flex' }} onClick={() => onOpen()}>
            {/* eslint-disable-next-line no-restricted-syntax */}
            Show all (+{activeFilters.length - 2})
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default ActiveFilters;
