'use client';

import { Button, CardHeader, CircularProgress, Flex, Heading, Tooltip } from '@chakra-ui/react';
import { CfCard, IconCaretRight } from '@cryptofi/core-ui';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { OnboardingStep } from '~/components';
import { onboardingSteps } from '~/constants';
import { useGetMostSevereStatusDetails, useOnboardingInfo, usePostTelemetryEvent } from '~/hooks';

import KycPendingAlert from './Modal/KycPendingAlert';

const CreateAccountCard = ({ onOnboardingModalOpen }: { onOnboardingModalOpen: () => void }) => {
  const { isSystemDown, systemStatusMessage } = useGetMostSevereStatusDetails();
  const { currentOnboardingStatus, currentStep, totalSteps } = useOnboardingInfo();

  const { trackEvent } = usePostTelemetryEvent();

  /* eslint-disable no-restricted-syntax */
  // show one more step to complete if KYC has been rejected
  const completedSteps = currentOnboardingStatus === 'rejected' ? totalSteps - 1 : currentStep - 1;
  const progress = Math.round((completedSteps / totalSteps) * 100);
  /* eslint-enable no-restricted-syntax */

  const handleCreateAccountClick = () => {
    // Fire telemetry event
    trackEvent(TelemetryClientSideEventsEnum.ClickedPortfolioCompleteProfileButtonClient);
    // Open onboarding modal
    onOnboardingModalOpen();
  };

  return (
    <CfCard gap={6}>
      <CardHeader p="0" display="flex" alignItems="center" gap={4}>
        <CircularProgress value={progress} color="brand.400" />

        <Heading as="h1" size="md" gap={4}>
          {`Create account (${completedSteps}/${totalSteps})`}
        </Heading>
      </CardHeader>

      {currentOnboardingStatus === 'rejected' && <KycPendingAlert />}

      {currentOnboardingStatus !== 'rejected' && (
        <>
          <Flex gap={4} direction={{ base: 'column', md: 'row' }} justifyContent="center">
            {onboardingSteps.slice(0, totalSteps).map((step) => (
              <OnboardingStep key={step.title} stepData={step} showDescription />
            ))}
          </Flex>

          <Flex direction="column" align="flex-end">
            <Tooltip hasArrow label={isSystemDown ? systemStatusMessage : null}>
              <Button
                isDisabled={isSystemDown}
                onClick={handleCreateAccountClick}
                variant="secondary"
                rightIcon={<IconCaretRight />}
              >
                Create account
              </Button>
            </Tooltip>
          </Flex>
        </>
      )}
    </CfCard>
  );
};

export default CreateAccountCard;
