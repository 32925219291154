import { usdToNumber } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { Camelized } from 'humps';
import { isNumber } from 'lodash';
import * as Yup from 'yup';

import { UserValues } from '~/codegen/types';
import { emailRegex } from '~/constants';
import { KycFormField } from '~/customTypes';

// KYC fields come from the generated UserValues interface, excluding unusedFields below
const unusedFields = ['member_type', 'previous_employer', 'company_name'] as const;

export const kycSchema = ({ neededFields }: { neededFields: KycFormField[] }) => {
  // do we need to collect user data for the current field
  // any field for the KYC form can be required or optional depending on whether
  // data has already been collected for that particular field and its required property
  const isNeeded = (name: string) => {
    return neededFields?.find((f) => f.name === name);
  };

  const errors = {
    isRequired: 'This field is required',
    minAge: 'Minimum age is 18 years old',
    ssnFormat: 'Please enter a valid SSN',
    postalCodeFormat: 'Please enter a valid postal code',
    phoneFormat: 'Please enter a valid phone number',
    emailFormat: 'Please enter a valid email',
    positiveNumber: 'Value must be a positive number',
    maxDollarAmount: 'Value must be less than $1,000,000,000',
    maxLength: 'Max length is 100 characters',
    inRange: 'Value must be between 0 and 100',
  };

  const optionalString = Yup.string().optional().max(100, errors.maxLength);

  // enable validation when a field is needed AND required
  const maybeRequired = ({ name, schema }: { name: KycFormField['name']; schema?: Yup.Schema }) => {
    const field = isNeeded(name);

    if (field?.required) {
      if (schema) {
        return schema;
      }

      return Yup.string().required(errors.isRequired).max(100, errors.maxLength);
    }

    return optionalString;
  };

  return Yup.object().shape({
    firstName: maybeRequired({ name: 'firstName' }),
    middleName: maybeRequired({ name: 'middleName' }),
    lastName: maybeRequired({ name: 'lastName' }),
    dateOfBirth: maybeRequired({
      name: 'dateOfBirth',
      schema: Yup.string().when([], ([], schema, resolve) => {
        if (!isNeeded('dateOfBirth')) {
          return Yup.string().optional();
        }

        // this prevents an invalid date error when the user hasn't entered anything
        if (!resolve.parent.dateOfBirth) {
          return Yup.string().required(errors.isRequired);
        }

        // validate the user is at least 18 years old
        if (resolve.parent.dateOfBirth) {
          return Yup.string().test('minAge', errors.minAge, (value) => {
            const dob = dayjs(value);
            // eslint-disable-next-line no-restricted-syntax
            const age = dayjs().diff(dob, 'day') / 365.25;

            return age >= 18;
          });
        }

        return schema;
      }),
    }),
    ssn: maybeRequired({
      name: 'ssn',
      schema: Yup.string()
        .required(errors.isRequired)
        .matches(/^\d{9}$/, errors.ssnFormat),
    }),
    address1: maybeRequired({ name: 'address1' }),
    address2: maybeRequired({ name: 'address2' }),
    city: maybeRequired({ name: 'city' }),
    state: maybeRequired({ name: 'state' }),
    postal: maybeRequired({
      name: 'postal',
      schema: Yup.string()
        .required(errors.isRequired)
        .matches(/^\d{5}$/, errors.postalCodeFormat),
    }),
    country: maybeRequired({ name: 'country' }),
    phone: maybeRequired({
      name: 'phone',
      schema: Yup.string()
        .required(errors.isRequired)
        .matches(/^\d{10}$/, errors.phoneFormat),
    }),
    email: maybeRequired({
      name: 'email',
      schema: Yup.string()
        .required(errors.isRequired)
        .matches(emailRegex, errors.emailFormat)
        .max(100, errors.maxLength),
    }),
    employmentStatus: maybeRequired({ name: 'employmentStatus' }),
    employer: maybeRequired({ name: 'employer' }),
    incomePerYearInteger: maybeRequired({
      name: 'incomePerYearInteger',
      schema: Yup.mixed().when([], ([], schema, resolve) => {
        const value = resolve.parent.incomePerYearInteger;
        const parsed = usdToNumber({ usd: value });

        if (value === '') {
          return Yup.mixed().test('notEmpty', errors.isRequired, () => false);
        }

        if (isNumber(parsed)) {
          return Yup.mixed()
            .test('positive', errors.positiveNumber, () => parsed > 0)
            .test('maxAmount', errors.maxDollarAmount, () => parsed < 1_000_000_000);
        }

        return schema;
      }),
    }),
    netWorthInteger: maybeRequired({
      name: 'netWorthInteger',
      schema: Yup.mixed().when([], ([], schema, resolve) => {
        const value = resolve.parent.netWorthInteger;
        const parsed = usdToNumber({ usd: value });

        if (value === '') {
          return Yup.mixed().test('notEmpty', errors.isRequired, () => false);
        }

        if (isNumber(parsed)) {
          return Yup.mixed()
            .test('positive', errors.positiveNumber, () => parsed > 0)
            .test('maxAmount', errors.maxDollarAmount, () => parsed < 1_000_000_000);
        }

        return schema;
      }),
    }),
    investmentObjective: maybeRequired({ name: 'investmentObjective' }),
    yearsStocksInteger: maybeRequired({
      name: 'yearsStocksInteger',
      schema: Yup.mixed().when([], ([], schema, resolve) => {
        const value = resolve.parent.yearsStocksInteger;

        if (value === '') {
          return Yup.mixed().test('notEmpty', errors.isRequired, () => false);
        }

        if (isNumber(Number(value))) {
          return Yup.mixed().test('positive', errors.inRange, () => value >= 0 && value < 100);
        }

        return schema;
      }),
    }),
    levelStocks: maybeRequired({ name: 'levelStocks' }),
    executiveOrShareholder: maybeRequired({ name: 'executiveOrShareholder' }),
    workForExchangeOrBrokerage: maybeRequired({ name: 'workForExchangeOrBrokerage' }),
    riskTolerance: maybeRequired({ name: 'riskTolerance' }),
    subjectToBackupWithholding: maybeRequired({ name: 'subjectToBackupWithholding' }),

    // optional fields for trusted contact
    trustedContactf: optionalString,
    trustedContactl: optionalString,
    trustedEmail: Yup.string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(emailRegex, errors.emailFormat)
      .max(100, errors.maxLength),
    trustedPhone: Yup.string()
      .optional()
      .matches(/^\d{10}$/, errors.phoneFormat),

    // TODO remove Etana fields once deprecated
    incomePerYear: maybeRequired({ name: 'incomePerYear' }),
    netWorth: maybeRequired({ name: 'netWorth' }),
  });
};

export type KycFormValues = Yup.InferType<ReturnType<typeof kycSchema>>;

// trigger a compiler error if keys from KycSchema do not match keys from FilteredUserValues type
// see https://stackoverflow.com/a/73461648

// eslint-disable-next-line unused-imports/no-unused-vars
function assert<T extends never>() {}
type TypeEqualityGuard<A, B> = Exclude<A, B> | Exclude<B, A>;

// create a type of all fields from the generated UserValues interface, excluding unused fields
type FilteredUserValues = Camelized<Omit<UserValues, (typeof unusedFields)[number]>>;

// an error here indicates that KycFormValues does not match FilteredUserValues
assert<TypeEqualityGuard<keyof KycFormValues, keyof FilteredUserValues>>();
