import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys } from 'humps';

import { RedisSecurityPricingInfo } from '~/codegen/types';
import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetSecurityDailyPrice = ({ ticker }: { ticker: string }) => {
  const { apiClient } = useAxiosContext();
  const url = apiRoutes.intrinio.getSecurityDailyPrice({ ticker });
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    enabled: Boolean(userAuthInfo) && isEnabled(['securities']),
    queryKey: [url, ticker, apiClient],
    queryFn: async () => {
      // prevent 500 errors when no search query is provided
      if (!ticker) {
        return [];
      }

      const response = await apiClient!.get(url);

      const formattedData: Camelized<RedisSecurityPricingInfo>[] = response?.data.map(
        (value: RedisSecurityPricingInfo) => {
          return camelizeKeys(value);
        },
      );

      return formattedData;
    },
  });
};

export default useGetSecurityDailyPrice;
