'use client';

import { Box, Button, Flex, Heading, Hide, Portal, Table, Tbody, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { CfLineChart, layoutMinWidth, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';
import { size } from 'lodash';
import { useRouter } from 'next/navigation';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { AssetListItem, AssetsSearchButton } from '~/components';
import { investButtonLabels, securitiesRefetchInterval } from '~/constants';
import {
  useFeatureSetEnabled,
  useGetFiInfo,
  useGetMostSevereStatusDetails,
  useGetSecurities,
  useGetTokenPrices,
  useGetUser,
  useIsTradingDisabled,
  usePostTelemetryEvent,
} from '~/hooks';
import { uiRoutes } from '~/routes';
import { getExploreAssetsSecurities } from '~/utils';

// TODO error and loading states

const AssetsList = ({ onOpenModal }: { onOpenModal: () => void }) => {
  const { isSystemDown, systemStatusMessage } = useGetMostSevereStatusDetails();
  const { isTradingDisabled } = useIsTradingDisabled();
  const { isEnabled } = useFeatureSetEnabled();
  const user = useGetUser();
  const fiInfo = useGetFiInfo();

  // eslint-disable-next-line no-restricted-syntax
  const hasInvestments = size(user?.data?.balance?.tokens) + size(user?.data?.balance?.securities) > 0;
  const ctaBtnLabel = hasInvestments ? investButtonLabels.trade : investButtonLabels.invest;

  const router = useRouter();

  const { isLargeBreakpoint } = useCfBreakpoint();
  const { trackEvent } = usePostTelemetryEvent();

  // Handle "Investment" button clicks; Fire tracking event, then the original onClick action
  const handleInvestButtonClick = () => {
    trackEvent(TelemetryClientSideEventsEnum.ClickedCryptoHomeInvestButtonClient);
    onOpenModal();
  };
  const featuredSecurities = getExploreAssetsSecurities(user?.data?.securitiesRiskProfile);

  const tokenPrices = useGetTokenPrices();
  const securitiesPrices = useGetSecurities({
    tickers: featuredSecurities.join(','),
    refetchInterval: securitiesRefetchInterval,
  });
  const handleAssetClick = ({ type, assetId }: { type: 'token' | 'security'; assetId: string }) => {
    trackEvent(TelemetryClientSideEventsEnum.ClickedCryptoHomeAssetButtonClient, { asset: assetId });
    if (type === 'security') {
      router.push(uiRoutes.assetDetailsSecurities(assetId));
    }
    if (type === 'token') {
      router.push(uiRoutes.assetDetailsCrypto(assetId));
    }
  };

  return (
    <>
      <Flex flexDir="column">
        <Flex
          justifyContent="space-between"
          borderBottom="solid 1px"
          borderColor={uiColors.coolElegance()}
          alignItems="center"
          pr={4}
        >
          <Heading as="h2" size="md" borderColor={uiColors.coolElegance()} p="4">
            {isEnabled(['securities']) ? 'Explore assets' : 'Assets'}
          </Heading>

          {isEnabled(['securities']) && <AssetsSearchButton />}
        </Flex>

        <Table>
          <Tbody>
            {isEnabled(['securities']) && (
              <>
                <Tr>
                  <Td colSpan={100} px="4" py="3">
                    <Flex justifyContent="space-between">
                      <Heading as="h3" fontSize="md" color={uiColors.sonicSilver()}>
                        Stocks
                      </Heading>

                      <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                        Offered by AdvisiFi LLC
                      </Text>
                    </Flex>
                  </Td>
                </Tr>

                {securitiesPrices.data?.map((security, i) => (
                  <AssetListItem
                    key={security.securitiesTicker}
                    assetId={security.securitiesTicker || ''}
                    assetName={security.name || ''}
                    previousClosingPrice={security.lastTradingDayInfo?.closePrice || undefined}
                    buyPrice={security.currentPrice?.value || undefined}
                    onAssetClick={() =>
                      handleAssetClick({ type: 'security', assetId: security.securitiesTicker || '' })
                    }
                    // eslint-disable-next-line no-restricted-syntax
                    isLastRow={i + 1 === size(securitiesPrices.data)}
                  />
                ))}
              </>
            )}

            {isEnabled(['crypto']) && (
              <>
                <Tr>
                  <Td colSpan={100} px="4" py="3">
                    <Flex justifyContent="space-between">
                      <Heading as="h3" fontSize="md" color={uiColors.sonicSilver()}>
                        Cryptocurrency
                      </Heading>

                      {fiInfo.data?.fiCustodianName !== 'Etana' && (
                        <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                          Offered by SAFE Trust Co.
                        </Text>
                      )}
                    </Flex>
                  </Td>
                </Tr>

                {tokenPrices.data?.map((token, i) => (
                  <AssetListItem
                    key={token.baseToken}
                    assetId={token.baseToken}
                    previousClosingPrice={token.sparkline?.[0].value}
                    buyPrice={token.buyPrice}
                    onAssetClick={() => handleAssetClick({ type: 'token', assetId: token.baseToken })}
                    // eslint-disable-next-line no-restricted-syntax
                    isLastRow={i + 1 === size(tokenPrices.data)}
                  >
                    {token.sparkline && (
                      <CfLineChart
                        data={token.sparkline}
                        dataKey="value"
                        sampleRate={4}
                        style={{ pointerEvents: 'none', maxWidth: '12rem', marginLeft: 'auto', marginRight: 'auto' }}
                      />
                    )}
                  </AssetListItem>
                ))}
              </>
            )}

            {/* using breakpoint here instead of responsive styles due to a issue on table borders */}
            {isLargeBreakpoint && (
              <Tr>
                <Td colSpan={100} p="4">
                  {/* Tooltip won't show if label is empty */}
                  <Tooltip hasArrow label={isSystemDown ? systemStatusMessage : null}>
                    <Button w="full" isDisabled={isTradingDisabled} onClick={handleInvestButtonClick}>
                      {ctaBtnLabel}
                    </Button>
                  </Tooltip>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>

      <Portal>
        <Hide above="lg">
          <Flex
            position="fixed"
            bottom="0"
            zIndex="2"
            bg="white"
            w="full"
            p="4"
            borderTop="solid 1px"
            borderColor={uiColors.coolElegance()}
            minW={layoutMinWidth}
            h="5rem"
          >
            <Tooltip hasArrow label={isSystemDown ? systemStatusMessage : null}>
              <Button w="full" h="full" isDisabled={isTradingDisabled} onClick={handleInvestButtonClick}>
                {ctaBtnLabel}
              </Button>
            </Tooltip>
          </Flex>

          <Box mt="5rem" />
        </Hide>
      </Portal>
    </>
  );
};

export default AssetsList;
