'use client';

import {
  Button,
  CardHeader,
  Flex,
  Heading,
  Hide,
  Show,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { CfCard, CfSpinner, dateFormats, formatUsd, utcDateToLocal } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { capitalize, size } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';

import { TokenEnum } from '~/codegen/types';
import { AssetLogo, TransactionAccordionList, TransactionStatus } from '~/components';
import { investButtonLabels } from '~/constants';
import { useGetMostSevereStatusDetails, useGetUserOrdersSearch, useIsTradingDisabled } from '~/hooks';
import { uiRoutes } from '~/routes';

dayjs.extend(utc);
dayjs.extend(timezone);

interface AssetTransactionHistoryTable {
  securitiesTicker?: string;
  token?: TokenEnum;
  onOpenModal: () => void;
  ownsCurrentAsset: boolean;
}

const AssetTransactionHistoryTable = ({
  ownsCurrentAsset,
  onOpenModal,
  securitiesTicker,
  token,
}: AssetTransactionHistoryTable) => {
  const transactions = useGetUserOrdersSearch({
    params: { token, securitiesTicker },
  });
  const { isSystemDown, systemStatusMessage } = useGetMostSevereStatusDetails();
  const { isTradingDisabled } = useIsTradingDisabled();
  const listLimit = 5;

  const hasTransactions = Boolean(size(transactions.data?.items));
  return (
    <CfCard>
      <Flex direction="column" justifyContent="center" w="full">
        <CardHeader p="0" pb="4">
          <Flex justify="space-between">
            <Heading as="h1" size="md">
              Latest trades
            </Heading>

            {!ownsCurrentAsset && (
              <Tooltip hasArrow label={isSystemDown ? systemStatusMessage : null}>
                <Button display={{ base: 'none', md: 'flex' }} isDisabled={isTradingDisabled} onClick={onOpenModal}>
                  {investButtonLabels.invest}
                </Button>
              </Tooltip>
            )}
          </Flex>
        </CardHeader>

        {transactions.isLoading && (
          <Show above="md">
            <CfSpinner />
          </Show>
        )}

        <Show above="md">
          <TableContainer flex="1">
            {!transactions.isLoading && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Asset</Th>

                    <Th>ID</Th>

                    <Th>Type</Th>

                    <Th>Date</Th>

                    <Th>Amount</Th>

                    <Th>Status</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {/* show most recent {listLimit} number of transactions */}
                  {!hasTransactions && (
                    <Tr>
                      <Td textAlign="center" colSpan={6}>
                        No trades found
                      </Td>
                    </Tr>
                  )}

                  {transactions.data?.items?.slice(0, listLimit).map((transaction) => (
                    <Tr key={transaction.txId}>
                      <Td>
                        <Flex alignItems="center" gap={4}>
                          <AssetLogo assetId={transaction.asset} />

                          {transaction.asset}
                        </Flex>
                      </Td>

                      <Td>{transaction.txId}</Td>

                      <Td>{capitalize(transaction.orderSide)}</Td>

                      <Td>
                        {utcDateToLocal({
                          dateFormat: dateFormats.dateWithTime,
                          timeString: transaction.transactTime,
                        })}
                      </Td>

                      <Td>
                        {formatUsd({
                          amount: Number(transaction.txGross),
                        })}
                      </Td>

                      <Td>
                        <TransactionStatus transaction={transaction} />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </TableContainer>
        </Show>

        <Hide above="md">
          <TransactionAccordionList transactions={transactions} limit={listLimit} />
        </Hide>

        {hasTransactions && (
          <Button
            alignSelf="center"
            as={NextLink}
            href={uiRoutes.transactionHistory({ securitiesTicker, token })}
            size="sm"
            variant="ghost"
          >
            View all
          </Button>
        )}
      </Flex>
    </CfCard>
  );
};

export default AssetTransactionHistoryTable;
