import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { bankAccountRefetchInterval } from '~/constants';
import { BankAccount } from '~/customTypes';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetUserBankAccounts = (args?: { refetchInterval?: number | false }) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const refetchInterval = args?.refetchInterval || bankAccountRefetchInterval;

  return useQuery({
    queryKey: [apiRoutes.bankAccounts(), apiClient],
    enabled: Boolean(userAuthInfo),
    refetchInterval: disableRefetch ? false : refetchInterval,
    queryFn: async () => {
      return apiClient!.get(apiRoutes.bankAccounts()).then((r) => {
        return camelizeKeys(r.data) as BankAccount[];
      });
    },
  });
};

export default useGetUserBankAccounts;
