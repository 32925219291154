import { AxiosError } from 'axios';

// type guard as we dont know what the detail key value will be in the data object returned from the server
const hasStringDetail = (error: any): error is { response: { data: { detail: string } } } => {
  return true;
};

export const getErrorDetail = (
  error:
    | {
        response: {
          data: {
            detail: string | Record<string, unknown>;
          };
        };
      }
    | AxiosError,
) => {
  return hasStringDetail(error) ? error.response.data.detail : "We're sorry, something went wrong.";
};
