'use client';

import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { CfCard, CfSpinner } from '@cryptofi/core-ui';
import { UseQueryResult } from '@tanstack/react-query';
import { Camelized } from 'humps';
import { size } from 'lodash';

import { SearchOrderPaginator } from '~/codegen/types';
import { TransactionAccordion } from '~/components';

interface Props extends FlexProps {
  transactions: UseQueryResult<Camelized<SearchOrderPaginator>, Error>;
  limit?: number;
}

const TransactionsAccordionList = ({ transactions, limit }: Props) => {
  const { data, isLoading } = transactions;
  const hasData = Boolean(size(data?.items));

  if (isLoading) {
    return (
      <CfCard>
        <CfSpinner />
      </CfCard>
    );
  }

  return (
    <Flex w="full">
      {!hasData && (
        <CfCard>
          <Text align="center">No trades found</Text>
        </CfCard>
      )}

      {hasData && (
        <Flex direction="column" justifyContent="center" w="full" gap="2" pb="4">
          {data?.items
            ?.slice(0, limit)
            .map((transaction) => (
              <TransactionAccordion key={transaction.txId} transaction={transaction} initiallyCollapsed />
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default TransactionsAccordionList;
