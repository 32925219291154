'use client';

import { IconButton, TableRowProps, Td, Tooltip, Tr } from '@chakra-ui/react';
import { IconDownload } from '@cryptofi/core-ui';
import { Camelized } from 'humps';
import { capitalize } from 'lodash';
import { useEffect } from 'react';

import { UserStatement } from '~/codegen/types';
import { useGetUserStatement } from '~/hooks';

interface Props extends TableRowProps {
  statement: Camelized<UserStatement>;
}

const StatementRow = ({ statement, ...rest }: Props) => {
  const {
    data: statementURL,
    isFetching,
    refetch,
  } = useGetUserStatement({
    statementId: statement.sKey || '',
    gcTime: 100, // unused/inactive cache data remains in memory for 100ms
  });

  useEffect(() => {
    if (statementURL) {
      // use sKey as the window target to prevent multiple tabs with the same file
      window.open(statementURL, statement.sKey || '');
    }
  }, [statementURL, statement.sKey]);

  return (
    <Tr {...rest}>
      <Td>{capitalize(statement.statementType)} statement</Td>

      <Td>
        {/* eslint-disable-next-line react/jsx-newline */}
        {statement.statementYear} {statement.statementQuarter}
      </Td>

      <Td display="flex" justifyContent="flex-end">
        <Tooltip label="Download statement" aria-label="Download statement" hasArrow>
          <IconButton
            aria-label="Download statement"
            isLoading={isFetching}
            variant="outline"
            onClick={() => refetch()}
          >
            <IconDownload />
          </IconButton>
        </Tooltip>
      </Td>
    </Tr>
  );
};

export default StatementRow;
