'use client';

import { Center, Text } from '@chakra-ui/react';
import { CfSpinner, layoutMinWidth } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { InvestmentPortalDisclosure } from '~/components';
import { useAuthenticateUser, useGetAuth, useGlobalStore, usePostAuth } from '~/hooks';

const Authentication = ({ children }: { children: React.ReactElement }) => {
  const [authCode, setAuthCode] = useState<string | null>(null);

  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const {
    data: authData,
    isLoading,
    isError,
  } = useGetAuth({
    authCode,
  });
  const postAuth = usePostAuth();
  const authenticateUser = useAuthenticateUser();
  const searchParams = useSearchParams();

  // User got redirected to us from NCR, save auth code from URL in state
  useEffect(() => {
    if (searchParams.has('code')) {
      setAuthCode(searchParams.get('code'));
    }
  }, [searchParams]);

  // check token expiration
  useEffect(() => {
    if (userAuthInfo?.expiryDate) {
      // When the user is authenticated, we check if the token is expired
      if (dayjs().unix() >= userAuthInfo?.expiryDate) {
        // enable getAuth fetching again
        // authorizeUser will be called again per dependency
        setAuthCode(null);
      }
    }
  }, [userAuthInfo?.expiryDate]);

  useEffect(() => {
    if (!userAuthInfo?.idToken) {
      authenticateUser({ authCode, redirectUrl: authData?.redirectUrl ?? '', postAuth });
    }
  }, [authCode, postAuth, authData, authenticateUser, userAuthInfo?.idToken]);

  // render the app for an authenticated user
  if (userAuthInfo?.idToken) {
    return children;
  }

  // TODO log any errors

  return (
    <Center minHeight="100vh" flexDirection="column" gap="4" textAlign="center" minW={layoutMinWidth} p="6">
      {(isLoading || postAuth.isPending) &&
        (['development', 'preview'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV!) ? (
          <InvestmentPortalDisclosure />
        ) : (
          <>
            <Text>Welcome to your personal investment platform. Loading your portfolio...</Text>

            <CfSpinner margin={5} boxSize="12" color="neutral" />
          </>
        ))}

      {(isError || postAuth.isError) && <Text>Something went wrong while authenticating, please try again.</Text>}
    </Center>
  );
};

export default Authentication;
