import { CfInput, CfSelect } from '@cryptofi/core-ui';
import { Camelized } from 'humps';
import { capitalize, toLower, upperFirst } from 'lodash';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useIMask } from 'react-imask';

import { KycFormField } from '~/customTypes';

import { KycFormValues } from './kycSchema';

// possible field types from the API
type apiFieldTypes = 'date' | 'email' | 'int' | 'select' | 'ssn' | 'phone' | 'postalCode' | 'text';

// convert valueType from API into the appropriate input type
const getInputType = ({ valueType, name }: { valueType: apiFieldTypes; name: string }) => {
  if (name === 'incomePerYearInteger' || name === 'netWorthInteger') {
    return 'currency';
  }

  switch (valueType) {
    case 'date':
      return 'date';
    case 'email':
      return 'email';
    case 'int':
      return 'number';

    // ssn, phone, postalCode, .etc
    default:
      return 'text';
  }
};

const DynamicInput = ({
  field,
  register,
  errors,
  setValue,
}: {
  field: Camelized<KycFormField>;
  register: UseFormRegister<KycFormValues>;
  errors: FieldErrors;
  setValue: UseFormSetValue<KycFormValues>;
}) => {
  const { name, label, valueType, required, options } = field;

  const maskLookup = {
    // keys must match a corresponding field name in KycSchema
    ssn: useIMask({ mask: '000-00-0000' }),
    phone: useIMask({ mask: '(000) 000-0000' }),
    postalCode: useIMask({ mask: '00000' }),
  };

  let formattedLabel = capitalize(label);
  // make sure Social Security is capitalized correctly
  if (field.name === 'ssn') {
    formattedLabel = formattedLabel.replace(/social security/gi, 'Social Security');
  }

  if (valueType === 'select') {
    return (
      <CfSelect
        label={formattedLabel}
        name={name}
        errorMessage={errors[name]?.message as string}
        isRequired={required}
        register={register}
        defaultValue=""
      >
        <option disabled value="">
          Select...
        </option>

        {options.map(({ name, value }) => (
          <option key={name} value={value}>
            {upperFirst(toLower(name))}
          </option>
        ))}
      </CfSelect>
    );
  }

  const mask = maskLookup[valueType as keyof typeof maskLookup]; // find the correct input mask, if defined for this valueType
  const inputType = getInputType({ valueType, name });
  const currencyProps = inputType === 'currency' ? { placeholder: '', allowDecimals: false } : {};
  const maskProps = mask
    ? {
        ref: mask.ref as React.MutableRefObject<HTMLInputElement>,
      }
    : {};

  return (
    <CfInput
      onInput={(e) => {
        // using onInput here because using onChange interferes with clearing validation errors
        // and masked inputs need to have their values set manually
        if (mask) {
          const target = e.target as HTMLInputElement;

          // mask.unmaskedValue is buggy and can be empty when autocompleting via mobile device,
          // so we use the raw value instead and replace all non-numeric characters
          setValue(name as keyof KycFormValues, target.value.replace(/\D/g, ''));
        }
      }}
      type={inputType}
      label={formattedLabel}
      name={name}
      errorMessage={errors[name]?.message as string}
      isRequired={required}
      register={register}
      {...maskProps}
      {...currencyProps}
    />
  );
};

export default DynamicInput;
