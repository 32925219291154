import { useCallback } from 'react';

import { useGetFiInfo } from '~/hooks';

const useFeatureSetEnabled = () => {
  const fiInfo = useGetFiInfo();
  const enabledFeatureSet = fiInfo.data?.enabledFeatureSet;

  /**
   * @description A function that checks if the specified feature set is enabled.
   *
   * @param {('securities' | 'crypto')[]} featureSet - An array of features to check
   * @return {boolean} The overall result of whether all features in the set are enabled
   */
  const isEnabled = useCallback(
    (featureSet: ('securities' | 'crypto')[]) => {
      return featureSet.every((feature) => {
        switch (feature) {
          case 'securities':
            return enabledFeatureSet?.isSecuritiesEnabled;
          case 'crypto':
            return enabledFeatureSet?.isCryptoEnabled;
        }
      });
    },
    [enabledFeatureSet?.isCryptoEnabled, enabledFeatureSet?.isSecuritiesEnabled],
  );

  return { isEnabled };
};

export default useFeatureSetEnabled;
