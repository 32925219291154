'use client';

import { Button, Flex, FlexProps, Heading, Portal, Text, Tooltip } from '@chakra-ui/react';
import { formatPercentage, formatUsd, layoutMinWidth, uiColors } from '@cryptofi/core-ui';

import { investButtonLabels } from '~/constants';
import { useGetMostSevereStatusDetails, useIsTradingDisabled } from '~/hooks';

interface Props extends FlexProps {
  amountUsd?: number;
  gainLossPercentage?: number;
  assetId: string;
  ownsCurrentAsset: boolean;
  onOpenModal: () => void;
}

const InvestmentCTA = ({
  amountUsd = 0,
  assetId,
  gainLossPercentage = 0,
  ownsCurrentAsset,
  onOpenModal,
  ...rest
}: Props) => {
  const { isSystemDown, systemStatusMessage } = useGetMostSevereStatusDetails();
  const { isTradingDisabled } = useIsTradingDisabled();

  const ctaBtnLabel = ownsCurrentAsset ? `${investButtonLabels.trade} ${assetId}` : investButtonLabels.invest;

  return (
    <Portal>
      <Flex
        position="fixed"
        bottom="0"
        zIndex="2"
        display={{ base: 'flex', md: 'none' }}
        justify="space-between"
        align="center"
        bg="white"
        w="full"
        p="4"
        borderTop="solid 1px"
        borderColor={uiColors.coolElegance()}
        minW={layoutMinWidth}
        {...rest}
      >
        {ownsCurrentAsset && (
          <Flex direction="column" gap="2">
            <Heading as="h1" size="xs">
              My investment
            </Heading>

            <Flex align="center" gap="2">
              <Text fontSize="xl" fontFamily="heading">
                {formatUsd({ amount: amountUsd })}
              </Text>

              <Text fontSize="sm" fontFamily="heading">
                {formatPercentage({ number: gainLossPercentage })}
              </Text>
            </Flex>
          </Flex>
        )}

        <Tooltip hasArrow label={isSystemDown ? systemStatusMessage : null}>
          <Button
            w={ownsCurrentAsset ? 'auto' : 'full'}
            isDisabled={isTradingDisabled}
            onClick={onOpenModal}
            display={{ base: 'block', lg: 'none' }}
          >
            {ctaBtnLabel}
          </Button>
        </Tooltip>
      </Flex>
    </Portal>
  );
};

export default InvestmentCTA;
