'use client';

import { Button, CardHeader, Flex, Heading, Text } from '@chakra-ui/react';
import { CfCard, IconCaretRight } from '@cryptofi/core-ui';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { EducationCard } from '~/components';
import { useGetFiInfo, usePostTelemetryEvent } from '~/hooks';
import { uiRoutes } from '~/routes';

const LearnSection = ({ ...rest }) => {
  const { trackEvent } = usePostTelemetryEvent();
  const fiInfo = useGetFiInfo();

  const sections = fiInfo.data?.cryptofiEducationContent;

  // Fire tracking event when "Learn" button is clicked
  const handleLearnButtonClick = () => {
    trackEvent(TelemetryClientSideEventsEnum.ClickedCryptoHomeLearnButtonClient);
  };

  const handleLearnArticleButtonClick = (sectionTitle: string) => {
    trackEvent(TelemetryClientSideEventsEnum.ClickedLearnArticleClient, { article: sectionTitle });
  };

  return (
    <CfCard {...rest}>
      <CardHeader p="0" display="flex" justifyContent="space-between" alignItems="center">
        <Heading as="h1" size="md">
          Learn
        </Heading>

        <Button
          rightIcon={<IconCaretRight />}
          variant="ghost"
          as={NextLink}
          href={uiRoutes.learn()}
          onClick={handleLearnButtonClick}
        >
          View all
        </Button>
      </CardHeader>

      <Text>Explore curated knowledge topics to enhance your investing prowess. Start your learning journey now!</Text>

      <Flex gap={2} justifyContent="center" direction={{ base: 'column', md: 'row' }}>
        {sections
          ?.slice(0, 3)
          .map((section) => (
            <EducationCard
              key={section.title}
              section={section}
              onClick={() => handleLearnArticleButtonClick(section.title)}
            />
          ))}
      </Flex>
    </CfCard>
  );
};

export default LearnSection;
