import { Button as ChakraButton, ButtonProps, forwardRef } from '@chakra-ui/react';

const Button = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <ChakraButton
      ref={ref}
      size="xs"
      bgColor="green.500 !important"
      color="black"
      transition="background-color 0.25s"
      sx={{
        '&:not(:disabled):hover, &:not(:disabled):focus, &:not(:disabled)[data-active]': {
          bgColor: 'green.300 !important',
        },
      }}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
});

export default Button;
