import { Button, Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import { CfCard, uiColors } from '@cryptofi/core-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce, startCase } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { Product } from '~/customTypes';
import { useFeatureSetEnabled, useGetFiInfo, useGetUser, usePostTelemetryEvent, usePostTerms } from '~/hooks';
import { hasAcceptedTermsForFeature } from '~/utils';

import TermsCheckbox from './TermsCheckbox';

const termsAndConditionsFormSchema = Yup.object().shape({
  advisifiBrokerageLicense: Yup.boolean().oneOf([true]),
  investifi: Yup.boolean().oneOf([true]),
  investifiBrokerageLicense: Yup.boolean().oneOf([true]),
  crypto: Yup.boolean().oneOf([true]),
  securities: Yup.boolean().oneOf([true]),
});
const TermsAndConditionsView = ({
  hasNewTerms,
  onClose,
  selectedProducts,
}: {
  hasNewTerms?: boolean;
  onClose: () => void;
  selectedProducts: Product[];
}) => {
  const user = useGetUser();
  const fiInfo = useGetFiInfo();

  const { isEnabled } = useFeatureSetEnabled();
  const postTerms = usePostTerms({
    selectedProducts: selectedProducts,
    options: {
      onSuccess: () => {
        user?.refetch();
      },
    },
  });

  const { trackEvent } = usePostTelemetryEvent();

  // close the modal when the user has accepted updated terms
  useEffect(() => {
    if (hasNewTerms && postTerms.isSuccess) {
      onClose();
    }
  }, [postTerms.isSuccess, onClose, hasNewTerms]);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(termsAndConditionsFormSchema),
    mode: 'onChange',
  });

  // Handle various click events in the form to send them to our telemetry service
  // debounce is needed to keep the event from firing twice onClick
  const handleTermsClickEvent = debounce((eventType: TelemetryClientSideEventsEnum) => {
    trackEvent(eventType);
  }, 500);

  const onSubmit = () => {
    postTerms.mutate();
  };

  if (!user?.data) {
    return <Skeleton height="20rem" mb={8} />;
  }

  return (
    <Flex flexDir="column" w="full" mb="12" gap="4">
      <Text color={uiColors.sonicSilver()} fontSize="sm">
        {hasNewTerms
          ? `We've updated our terms! As part of a periodic review, ${fiInfo.data?.fiName} has updated its user terms. Please accept the latest version to continue investing.`
          : 'To get started, please review and accept the Terms and Conditions.'}
      </Text>

      {fiInfo.data?.termsAndConditions && (
        <>
          {!user.data?.termsAndConditions?.investifi?.dateAccepted && (
            <CfCard background={uiColors.lighthouse()}>
              <TermsCheckbox
                termsParty="InvestiFi"
                register={register}
                name="investifi"
                onClick={() => {
                  // the form value will not have been updated prior to this firing thus we check what the previous state was
                  const wasPreviouslyChecked = getValues('investifi');
                  if (!wasPreviouslyChecked) {
                    handleTermsClickEvent(
                      TelemetryClientSideEventsEnum.TCModalClickedAgreeToTermsAndConditionsCheckboxClient,
                    );
                  }
                }}
                termsUserResponse={fiInfo.data.termsAndConditions.investifi}
              />
            </CfCard>
          )}

          {isEnabled(['crypto']) &&
            selectedProducts.includes('crypto') &&
            !hasAcceptedTermsForFeature({ user: user.data, featureSet: 'crypto' }) && (
              <>
                <Flex gap="2" alignItems="center">
                  <Heading as="h3" size="xs" color={uiColors.sonicSilver()}>
                    Cryptocurrencies
                  </Heading>

                  {fiInfo.data.fiCustodianName !== 'Etana' && (
                    <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                      Offered by SAFE Trust Co.
                    </Text>
                  )}
                </Flex>

                <CfCard background={uiColors.lighthouse()}>
                  <TermsCheckbox
                    termsParty={startCase(fiInfo.data.fiCustodianName || 'crypto provider')}
                    register={register}
                    name="crypto"
                    onClick={() => {
                      // the form value will not have been updated prior to this firing thus we check what the previous state was
                      const wasPreviouslyChecked = getValues('crypto');
                      if (!wasPreviouslyChecked) {
                        handleTermsClickEvent(
                          TelemetryClientSideEventsEnum.TCModalClickedAgreeToCustodianTermsAndConditionsCheckboxClient,
                        );
                      }
                    }}
                    termsUserResponse={fiInfo.data.termsAndConditions.crypto}
                  />
                </CfCard>
              </>
            )}

          {isEnabled(['securities']) &&
            selectedProducts.includes('security') &&
            !hasAcceptedTermsForFeature({ user: user.data, featureSet: 'securities' }) && (
              <>
                <Flex gap="2" alignItems="center">
                  <Heading as="h3" size="xs" color={uiColors.sonicSilver()}>
                    Self-directed securities (stocks)
                  </Heading>

                  <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                    Offered by AdvisiFi LLC
                  </Text>
                </Flex>

                <CfCard background={uiColors.lighthouse()}>
                  {!user.data?.termsAndConditions?.securities?.dateAccepted && (
                    <TermsCheckbox
                      termsParty={startCase(fiInfo.data.fiSecuritiesProviderName || 'securities provider')}
                      register={register}
                      name="securities"
                      termsUserResponse={fiInfo.data.termsAndConditions.securities}
                    />
                  )}

                  {!user.data?.termsAndConditions?.investifiBrokerageLicense?.dateAccepted && (
                    <TermsCheckbox
                      termsParty="CDS Brokerage"
                      register={register}
                      name="investifiBrokerageLicense"
                      termsUserResponse={fiInfo.data.termsAndConditions.investifiBrokerageLicense}
                    />
                  )}

                  {!user.data?.termsAndConditions?.advisifiBrokerageLicense?.dateAccepted && (
                    <TermsCheckbox
                      termsParty="AdvisiFi"
                      register={register}
                      name="advisifiBrokerageLicense"
                      termsUserResponse={fiInfo.data.termsAndConditions.advisifiBrokerageLicense}
                    />
                  )}
                </CfCard>
              </>
            )}
        </>
      )}

      <Flex alignSelf="flex-end">
        <Button
          isDisabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          isLoading={postTerms.isPending || user.isRefetching}
        >
          Agree and continue
        </Button>
      </Flex>
    </Flex>
  );
};

export default TermsAndConditionsView;
