'use client';

import { Box, Button, CardHeader, Flex, FlexProps, Heading, Skeleton, Text } from '@chakra-ui/react';
import { CfCard } from '@cryptofi/core-ui';
import { useState } from 'react';

interface Props extends FlexProps {
  displayName: string;
  ownsCurrentAsset: boolean;
  isLoading?: boolean;
  description: string[];
}

const AboutAsset = ({ displayName, ownsCurrentAsset, isLoading = false, description }: Props) => {
  // if the user has traded this asset, expand the about section fill up the page
  // if the user has not traded this asset, collapse the about section

  const defaultLineCount = 6;
  const [lineCount, setLineCount] = useState<number | undefined>(ownsCurrentAsset ? defaultLineCount : undefined);
  const handleToggle = () => setLineCount(lineCount ? undefined : defaultLineCount);

  return (
    <CfCard>
      <CardHeader p="0">
        <Flex justify="space-between">
          <Heading as="h1" size="md">
            About {displayName}
          </Heading>
        </Flex>
      </CardHeader>

      <Skeleton display="flex" flexDirection="column" isLoaded={!isLoading}>
        <Box position="relative">
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bgGradient="linear(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)"
            // hide gradient if the box is expanded
            zIndex={!lineCount ? -100 : 1}
          />

          <Box noOfLines={lineCount}>
            {description.map((desc, index) => (
              <Text key={index} pt={4}>
                {desc}
              </Text>
            ))}
          </Box>
        </Box>

        <Button alignSelf="center" size="sm" variant="ghost" onClick={handleToggle} my="6">
          {!lineCount ? 'Show less' : 'Keep reading'}
        </Button>
      </Skeleton>
    </CfCard>
  );
};

export default AboutAsset;
