'use client';

import { Link } from '@chakra-ui/next-js';
import { Text } from '@chakra-ui/react';
import { CfCheckbox, CfCheckboxProps, uiColors } from '@cryptofi/core-ui';
import { Fragment } from 'react';

import { IndividualTermsAndConditionsUserResponse } from '~/codegen/types';

interface TermsCheckboxProps extends CfCheckboxProps {
  termsUserResponse?: IndividualTermsAndConditionsUserResponse;
  termsParty: string;
}

const getSeparator = ({ index, length }: { index: number; length: number }) => {
  // don't render separator for last item
  // eslint-disable-next-line no-restricted-syntax
  if (index === length - 1) {
    return '';
  }

  // if theres only 2, this is the only separator
  // if theres more than 2, its the last separator
  // eslint-disable-next-line no-restricted-syntax
  if ((index === 0 && length === 2) || (length > 2 && index === length - 2)) {
    return ' and ';
  }

  // this only leaves commas, which will show when theres more than 2
  if (length > 2) {
    return ', ';
  }
};

export default function TermsCheckbox({ termsUserResponse, termsParty, ...props }: TermsCheckboxProps) {
  if (!termsUserResponse?.policies) {
    return null;
  }

  const label = (
    <>
      <Text color={uiColors.black()}>
        I agree to
        {termsUserResponse.policies.map((policy, index) => {
          return (
            <Fragment key={index}>
              {index === 0 ? ` the ${termsParty} ` : 'the '}

              <Link textDecor="underline" key={policy.label} href={policy.url} target={policy.url}>
                {policy.label}
              </Link>

              {termsUserResponse.policies && getSeparator({ index, length: termsUserResponse.policies.length })}
            </Fragment>
          );
        })}
      </Text>

      <Text>{termsUserResponse.description}</Text>
    </>
  );

  return (
    <CfCheckbox
      flexDir="column"
      pt="2px"
      pl="2px"
      verticalAlign="top"
      label={label}
      labelPosition="right"
      size="sm"
      {...props}
    />
  );
}
