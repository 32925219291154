import { Box, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { CfPrettyWrap, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { KycFormField } from '~/customTypes';

import DynamicInput from './DynamicInput';
import { KycFormValues } from './kycSchema';
import RiskRadioCardGroup from './RiskRadioCardGroup';

const KycSection = ({
  fields,
  register,
  errors,
  setValue,
  heading,
}: {
  fields: KycFormField[];
  register: UseFormRegister<KycFormValues>;
  errors: FieldErrors;
  setValue: UseFormSetValue<KycFormValues>;
  heading: string;
}) => {
  const { isAboveSmallBreakpoint } = useCfBreakpoint();
  const isFieldFullWidth = (field: KycFormField) => {
    return (
      isAboveSmallBreakpoint && (field.name === 'executiveOrShareholder' || field.name === 'workForExchangeOrBrokerage')
    );
  };

  return (
    <Box>
      <Heading as="h2" size="sm" color={uiColors.sonicSilver()} mb="6">
        {heading}
      </Heading>

      {heading === 'contact' && (
        <Text color={uiColors.sonicSilver()} mb="6">
          Please ensure you have access to these contact methods.
        </Text>
      )}

      <Grid templateColumns={isAboveSmallBreakpoint ? 'repeat(2, 1fr)' : '1fr'} gap={6}>
        {fields.map((field) => {
          if (field.name === 'riskTolerance') {
            return (
              <GridItem key={field.name} colSpan={isAboveSmallBreakpoint ? 2 : 1} w="full">
                <RiskRadioCardGroup field={field} register={register} errors={errors} setValue={setValue} />
              </GridItem>
            );
          }

          const isFullWidth = isFieldFullWidth(field);

          return (
            <GridItem key={field.name} w="full" colSpan={isFullWidth ? 2 : 1}>
              <CfPrettyWrap isEnabled={isFullWidth}>
                <DynamicInput field={field} register={register} errors={errors} setValue={setValue} />
              </CfPrettyWrap>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export default KycSection;
