import {
  Flex,
  FlexProps,
  forwardRef,
  Heading,
  Skeleton,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { formatUsd, uiColors } from '@cryptofi/core-ui';

import { AssetLogo } from '~/components';
import { AllAssetIds } from '~/customTypes';
import { uiConstants } from '~/theme';

interface Props extends FlexProps {
  valueType: 'portfolio' | 'asset';
  hideBalance: boolean;
  formattedBalance: string | number;
  totalValue: number;
  isNetGain: boolean;
  delta: number;
  isLoaded: boolean;
  assetId?: AllAssetIds;
}

const TotalValue = forwardRef<Props, 'div'>(
  ({ valueType, hideBalance, formattedBalance, totalValue, isNetGain, delta, isLoaded, assetId }, ref) => {
    return (
      <Flex
        ref={ref}
        position="absolute"
        top="4"
        left={{ base: 0, lg: 4 }}
        right={{ base: 0, lg: 'auto' }}
        margin="auto"
        bg={uiColors.white(0.2)}
        border="solid 1px"
        borderColor={uiColors.coolElegance()}
        p="4"
        borderRadius="xl"
        backdropFilter={uiConstants.backdropBlur}
        // eslint-disable-next-line no-restricted-syntax
        minWidth={`${String(formattedBalance).length * 1.5}rem`}
        width="fit-content"
        opacity={hideBalance ? '0' : '1'}
        transition="opacity 0.75s ease-in-out"
        pointerEvents="none"
        alignItems="flex-start"
        gap="2"
        height="6.5rem"
      >
        <Skeleton h="full" isLoaded={isLoaded} w="full">
          <Flex gap="2" margin={{ base: '0 auto', lg: 'initial' }} width="full">
            <Stat display="block" flex="none" position="static" width="full">
              <Flex
                gap="2"
                alignItems="center"
                justifyContent={{
                  base: 'center',
                  lg: 'flex-start',
                }}
              >
                <StatLabel>
                  <Heading as="h1" size="xs">
                    Current {valueType === 'portfolio' ? 'value' : 'price'}
                  </Heading>
                </StatLabel>

                {totalValue > 0 && (
                  <>
                    <VisuallyHidden>
                      {`${valueType === 'portfolio' ? 'Balance' : 'Token price'} change over the selected period:`}
                    </VisuallyHidden>

                    <Text
                      lineHeight="1.5"
                      fontSize="sm"
                      marginTop="2px"
                      color={isNetGain ? uiColors.mint() : uiColors.heroicRed()}
                    >
                      {formatUsd({
                        amount: delta,
                        signDisplay: true,
                      })}
                    </Text>
                  </>
                )}
              </Flex>

              <StatNumber
                fontSize="xx-large"
                textAlign={{ base: 'center', lg: 'left' }}
                width="calc(100% - 2rem)"
                position="absolute"
                left="calc(50%)"
                transform="translateX(calc(-50%))"
                whiteSpace="nowrap"
              >
                <Flex alignItems="center" gap="2" justifyContent={{ base: 'center', lg: 'flex-start' }} w="full">
                  {assetId && <AssetLogo assetId={assetId} boxSize="6" />}

                  {formattedBalance}
                </Flex>
              </StatNumber>
            </Stat>
          </Flex>
        </Skeleton>
      </Flex>
    );
  },
);

export default TotalValue;
