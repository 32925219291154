import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys, decamelizeKeys } from 'humps';
import { FieldValues } from 'react-hook-form';

import { UserStatement } from '~/codegen/types';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

interface StatementFormData extends FieldValues {
  statementType?: string;
  statementYear?: string;
}

const useGetUserStatements = ({ params }: { params?: StatementFormData }) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo),
    queryKey: [apiRoutes.statements(), params, apiClient],
    queryFn: async () => {
      return apiClient!
        .get(apiRoutes.statements(), {
          params: decamelizeKeys(params),
        })
        .then((r) => camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as Camelized<UserStatement>[]);
    },
  });
};

export default useGetUserStatements;
