'use client';
import { Flex, Heading, IconButton, ModalProps } from '@chakra-ui/react';
import { CfModal, CfSpinner, IconCaretLeft } from '@cryptofi/core-ui';
import { useCallback, useEffect, useState } from 'react';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { OnboardingModalViews } from '~/customTypes';
import { useFeatureSetEnabled, useGetKycFields, useOnboardingInfo, usePostTelemetryEvent } from '~/hooks';

import KycInfoView from './KycInfoView';
import KycPendingAlert from './KycPendingAlert';
import ProgressBar from './ProgressBar';
import SelectProductsView from './SelectProductsView';
import TermsAndConditionsView from './TermsAndConditionsView';
import WelcomeCryptoView from './WelcomeCryptoView';
import WelcomeSecuritiesView from './WelcomeSecuritiesView';

interface Props extends Omit<ModalProps, 'children'> {
  hasNewTerms?: boolean;
}

const OnboardingModal = ({ onClose, isOpen, hasNewTerms, ...rest }: Props) => {
  const {
    currentOnboardingStatus,
    isOnboarded,
    isOnboardedSecurities,
    isOnboardedCrypto,
    selectedProducts,
    setSelectedProducts,
  } = useOnboardingInfo();
  const { isEnabled } = useFeatureSetEnabled();
  // TODO: get welcome screen working and update for welcome securities
  const [modalView, setModalView] = useState<OnboardingModalViews>('loading');

  const kycFields = useGetKycFields({ selectedProducts });
  const { trackEvent } = usePostTelemetryEvent();
  const trackOnClose = useCallback(() => {
    const kycForm = document.getElementById('kycForm') as HTMLFormElement;
    if (kycForm) {
      const kycFields = Array.prototype.slice.call(kycForm.elements) as HTMLInputElement[];
      const capturedFields = Array.from(kycFields)
        .map((field) => {
          return field.value ? field.name : null;
        })
        .filter((fieldName) => fieldName !== null);

      trackEvent(TelemetryClientSideEventsEnum.KYCModalClosedKYCFormClient, { capturedFields });
    }

    onClose();
  }, [onClose, trackEvent]);

  // setModalView useEffect
  useEffect(() => {
    if (isOpen) {
      if (!currentOnboardingStatus) {
        setModalView('loading');
      }
      if (currentOnboardingStatus === 'rejected') {
        setModalView('kycPending');
      } else if (
        modalView !== 'termsAndConditions' &&
        (currentOnboardingStatus === 'notAcceptedTandC' || hasNewTerms)
      ) {
        if (hasNewTerms) {
          setModalView('termsAndConditions');
        } else if (isEnabled(['securities', 'crypto'])) {
          if (!isOnboarded) {
            setModalView('selectProducts');
          } else if (!isOnboardedSecurities) {
            setModalView('welcomeSecurities');
          } else if (!isOnboardedCrypto) {
            setModalView('welcomeCrypto');
          }
        } else if (isEnabled(['securities'])) {
          setModalView('welcomeSecurities');
        } else if (isEnabled(['crypto'])) {
          setModalView('welcomeCrypto');
        }
      } else if (currentOnboardingStatus === 'notStartedKyc' || currentOnboardingStatus === 'approved') {
        setModalView('kycInfo');
      }
    }
  }, [
    isOpen,
    setModalView,
    hasNewTerms,
    selectedProducts,
    isOnboarded,
    isEnabled,
    isOnboardedSecurities,
    isOnboardedCrypto,
    modalView,
    currentOnboardingStatus,
  ]);

  const views = {
    loading: {
      bodyContent: <CfSpinner />,
      hasProgressBar: false,
      headerText: undefined,
    },
    welcomeSecurities: {
      bodyContent: <WelcomeSecuritiesView setModalView={setModalView} />,
      hasProgressBar: false,
      headerText: undefined,
    },
    welcomeCrypto: {
      bodyContent: <WelcomeCryptoView setModalView={setModalView} />,
      hasProgressBar: false,
      headerText: undefined,
    },
    selectProducts: {
      bodyContent: (
        <SelectProductsView
          setSelectedProducts={setSelectedProducts}
          setModalView={setModalView}
          selectedProducts={selectedProducts}
        />
      ),
      hasProgressBar: false,
      headerText: undefined,
    },
    termsAndConditions: {
      bodyContent: (
        <TermsAndConditionsView hasNewTerms={hasNewTerms} selectedProducts={selectedProducts} onClose={onClose} />
      ),
      hasProgressBar: !hasNewTerms,
      headerText: hasNewTerms ? 'Updated Terms and Conditions' : 'Create account',
    },
    kycInfo: {
      bodyContent: (
        <KycInfoView
          kycFields={kycFields}
          selectedProducts={selectedProducts}
          onClose={onClose}
          isOnboarded={currentOnboardingStatus === 'approved'}
        />
      ),
      hasProgressBar: true,
      headerText: 'Create account',
    },
    kycPending: {
      bodyContent: <KycPendingAlert />,
      hasProgressBar: true,
      headerText: 'Create account',
    },
  };

  const showBackButton =
    modalView === 'termsAndConditions' && isEnabled(['securities', 'crypto']) && isOnboarded === false && !hasNewTerms;

  return (
    <CfModal
      headerContent={
        <Heading as="h1" size="md" textAlign="center">
          {showBackButton && (
            <IconButton
              icon={<IconCaretLeft __css={{ path: { fill: 'black !important' } }} />}
              position="absolute"
              left="3"
              top="2"
              variant="ghost"
              aria-label="Back to select products"
              size="md"
              onClick={() => {
                setModalView('selectProducts');
              }}
              _hover={{ bg: 'blackAlpha.100' }}
              _focus={{ bg: 'blackAlpha.100' }}
            />
          )}

          {views[modalView].headerText}
        </Heading>
      }
      onClose={trackOnClose}
      closeOnOverlayClick={false}
      closeOnEsc={!hasNewTerms}
      isDisabledCloseButton={hasNewTerms}
      isOpen={isOpen}
      size="4xl"
      {...rest}
    >
      <Flex direction="column" px={{ md: '10' }} gap="4">
        {views[modalView].hasProgressBar && <ProgressBar />}

        {views[modalView].bodyContent}
      </Flex>
    </CfModal>
  );
};

export default OnboardingModal;
