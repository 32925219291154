import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react';
import { CfSelectOption, uiColors } from '@cryptofi/core-ui';
import { Camelized } from 'humps';
import { capitalize } from 'lodash';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { KycFormField } from '~/customTypes';

import { KycFormValues } from './kycSchema';

const RiskRadioCard = (props: {
  option: Camelized<CfSelectOption>;
  fieldName: keyof KycFormValues;
  register: UseFormRegister<KycFormValues>;
  radioProps: UseRadioProps;
  isError: boolean;
}) => {
  const { fieldName, register, option, radioProps, isError } = props;
  const { state, getRootProps, getInputProps, getLabelProps } = useRadio(radioProps);
  const radioDescription = {
    'Very Conservative':
      'I am interested in extremely lower risk investments and focused on capital preservation, even if it means lower returns. I have a very low tolerance for risk to my principal, but understand fixed income exchange traded funds (ETFs) still carry risk to my principal. A Very Conservative investor might invest a portion of his or her portfolio in fixed income exchange traded funds (ETFs).',
    Conservative:
      'I am interested in low risk investments and am focused on income generation and stability, even it means lower returns. I have a low tolerance for risk and understand that investing in individual stocks and fixed income carries risk to my principal. A Conservative investor might invest a portion of his or her portfolio in individual S&P 500 stocks and certain exchange traded funds.',
    Moderate:
      'I am interested in medium risk investments and am focused on balancing capital appreciation and income generation. I have a moderate tolerance for risk and understand I could lose a portion, or all of my principal. A Moderate investor might invest a portion of his or her portfolio in a mix of exchange traded funds, as well as large cap stocks, each of which carry risk of loss of principal.',
    Aggressive:
      'I am interested in growth and capital appreciation and am willing to accept significant risk in order to generate returns on my investments. I understand that aggressive investment strategies carry higher risk and volatility and that I could lose a portion, or all of my principal. An Aggressive investor might invest a portion of his or her portfolio in exchange traded funds, as well as small cap, mid cap, and large cap stocks, each of which carry risk of loss of principal.',
  }?.[option.name];

  return (
    <FormLabel {...getRootProps()} cursor="pointer" w="full" maxW={{ base: '100%', md: 'calc(50% - 0.5rem)' }} m="0">
      <Flex
        aria-checked={state.isChecked}
        display="flex"
        flexDirection="column"
        gap="4"
        w="full"
        h="full"
        borderStyle="solid"
        borderWidth="1px"
        borderColor={isError ? 'red.500' : uiColors.coolElegance()}
        _hover={{ boxShadow: `0px 2px 8px 0px ${uiColors.black(0.08)}` }}
        _focus={{ boxShadow: `0px 2px 8px 0px ${uiColors.black(0.08)}` }}
        borderRadius="0.75rem"
        px="4"
        py="6"
        role="radio"
        _checked={{
          borderColor: 'brand.400',
        }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading {...getLabelProps({ 'aria-label': 'true' })} as="h3" color="black" fontSize="xl">
            {capitalize(option.name)}
          </Heading>

          <input
            type="radio"
            {...register(fieldName)}
            {...getInputProps({ 'aria-hidden': 'true' })}
            style={{ height: 0, width: 0, position: 'absolute', zIndex: -1 }}
          />

          <Flex
            p="1"
            h="1.5rem"
            w="1.5rem"
            alignItems="center"
            borderRadius="100%"
            border="2px solid"
            borderColor="brand.400"
          >
            {radioProps.isChecked && <Box w="full" h="full" bgColor="brand.400" borderRadius="100%" />}
          </Flex>
        </Flex>

        <Text fontSize="sm" fontFamily="body">
          {radioDescription}
        </Text>
      </Flex>
    </FormLabel>
  );
};

const RiskRadioCardGroup = ({
  field,
  register,
  errors,
  setValue,
}: {
  field: Camelized<KycFormField>;
  register: UseFormRegister<KycFormValues>;
  errors: FieldErrors;
  setValue: UseFormSetValue<KycFormValues>;
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field.name,
    onChange: (value) => {
      setValue(field.name as keyof KycFormValues, value, { shouldValidate: true });
    },
  });

  return (
    <Flex flexDirection="column" {...getRootProps({ 'aria-hidden': 'false', 'aria-required': 'true' })}>
      <FormControl isRequired isInvalid={Boolean(errors[field.name]?.message)}>
        <FormLabel mb="2" color={uiColors.sonicSilver()}>
          Risk tolerance
        </FormLabel>
      </FormControl>

      <Text mb="4" fontSize="sm" color={uiColors.sonicSilver()}>
        What kind of investor are you?
      </Text>

      <Flex justifyContent="space-between" gap="4" flexWrap="wrap" w="full">
        {field.options.map((option) => (
          <RiskRadioCard
            key={option.value}
            option={option}
            fieldName={field.name as keyof KycFormValues}
            register={register}
            radioProps={getRadioProps({ value: option.value })}
            isError={Boolean(errors[field.name])}
          />
        ))}
      </Flex>

      {errors[field.name] && (
        <FormControl isInvalid={Boolean(errors[field.name]?.message)}>
          <FormErrorMessage>{errors[field.name]?.message as string}</FormErrorMessage>
        </FormControl>
      )}
    </Flex>
  );
};

export default RiskRadioCardGroup;
