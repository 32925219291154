'use client';

import { Box, BoxProps, Text } from '@chakra-ui/react';
import { uiColors } from '@cryptofi/core-ui';

import { useGetMostSevereStatusDetails } from '~/hooks';

const SystemStatusMessage = ({ ...rest }: BoxProps) => {
  const { isSystemDown, systemStatusMessage } = useGetMostSevereStatusDetails();

  if (!isSystemDown) {
    return null;
  }

  return (
    <Box {...rest}>
      <Text color={uiColors.heroicRed()} fontSize="sm" my={4}>
        {systemStatusMessage}
      </Text>
    </Box>
  );
};

export default SystemStatusMessage;
