'use client';
import { Box, Card, Fade, Flex, Heading, Text } from '@chakra-ui/react';
import { CfSpinner, uiColors } from '@cryptofi/core-ui';
import { useEffect, useState } from 'react';

import { CustodianEnum } from '~/codegen/types';

export default function InvestmentPortalDisclosure() {
  const [currentAssetType, setCurrentAssetType] = useState<'crypto' | 'securities'>('securities');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAssetType((prevType) => (prevType === 'crypto' ? 'securities' : 'crypto'));
    }, 2_000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box maxWidth="40rem">
      <Flex alignItems="center">
        <Heading textAlign="left" size="md" as="h2">
          You are leaving online banking and entering the investments portal
        </Heading>

        <Box>
          <CfSpinner />
        </Box>
      </Flex>

      <Card
        mt={3}
        key={currentAssetType}
        color={uiColors.sonicSilver()}
        textAlign="left"
        fontSize="sm"
        p={3}
        background={uiColors.lighthouse()}
        variant="filled"
      >
        {/* <Fade unmountOnExit={true} in={currentAssetType === 'crypto'}>
          <Flex flexDir="column" gap={3}>
            {disclosures['sFOX' as CustodianEnum].crypto().map((paragraph, i) => (
              <Text key={i}>{paragraph}</Text>
            ))}
          </Flex>
        </Fade>

        <Fade unmountOnExit={true} in={currentAssetType === 'securities'}>
          <Flex flexDir="column" gap={3}>
            {disclosures['sFOX' as CustodianEnum].securities().map((paragraph, i) => (
              <Text key={i}>{paragraph}</Text>
            ))}
          </Flex>
        </Fade> */}
      </Card>
    </Box>
  );
}
