import { useEffect, useState } from 'react';

import { KYCStatusEnum } from '~/codegen/types';
import { OnboardingStatus, Product } from '~/customTypes';
import { useFeatureSetEnabled, useGetUser } from '~/hooks';
import { hasAcceptedTermsForFeature } from '~/utils';

const stepNumberLookup: {
  [key: string]: number;
} = {
  notAcceptedTandC: 1,
  notStartedKyc: 2,
};

const getOnboardingStatus = ({
  kycStatus,
  hasAcceptedAllRequiredTerms,
}: {
  kycStatus?: KYCStatusEnum | null;
  hasAcceptedAllRequiredTerms: boolean | undefined;
}) => {
  if (!kycStatus || !hasAcceptedAllRequiredTerms) {
    return 'notAcceptedTandC';
  }

  // map KYC status to onboarding status
  const statusLookup: { [key in KYCStatusEnum]?: OnboardingStatus } = {
    [KYCStatusEnum.NOT_STARTED]: 'notStartedKyc',
    [KYCStatusEnum.APPROVED]: 'approved',
    [KYCStatusEnum.REJECTED]: 'rejected',
  };

  return statusLookup[kycStatus];
};

const useOnboardingInfo = () => {
  const user = useGetUser();
  const { isEnabled } = useFeatureSetEnabled();
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [currentStep, setCurrentStep] = useState(1);

  const hasAcceptedSecuritiesTerms =
    user?.data &&
    isEnabled(['securities']) &&
    hasAcceptedTermsForFeature({ user: user.data, featureSet: 'securities' });

  const hasAcceptedCryptoTerms =
    user?.data && isEnabled(['crypto']) && hasAcceptedTermsForFeature({ user: user.data, featureSet: 'crypto' });

  const cryptoOnboardingStatus = getOnboardingStatus({
    kycStatus: user?.data?.cryptoKycStatus,
    hasAcceptedAllRequiredTerms: hasAcceptedCryptoTerms,
  });

  const securitiesOnboardingStatus = getOnboardingStatus({
    kycStatus: user?.data?.securitiesKycStatus,
    hasAcceptedAllRequiredTerms: hasAcceptedSecuritiesTerms,
  });

  const hasNewTerms =
    (user?.data?.cryptoKycStatus === 'APPROVED' && !hasAcceptedCryptoTerms) ||
    (user?.data?.securitiesKycStatus === 'APPROVED' && !hasAcceptedSecuritiesTerms);

  // assumes that if selectedProducts has both crypto and securities, cryptoOnboardingStatus and securitiesOnboardingStatus are the same
  const getCurrentOnboardingStatus = () => {
    if (selectedProducts.includes('crypto')) {
      return cryptoOnboardingStatus;
    } else if (selectedProducts.includes('security')) {
      return securitiesOnboardingStatus;
    } else {
      return cryptoOnboardingStatus || securitiesOnboardingStatus;
    }
  };

  const currentOnboardingStatus = getCurrentOnboardingStatus();

  // determine the current status and step of onboarding
  useEffect(() => {
    if (currentOnboardingStatus) {
      setCurrentStep(stepNumberLookup[currentOnboardingStatus]);
    }
  }, [currentOnboardingStatus]);

  const isOnboardedCrypto = Boolean(cryptoOnboardingStatus === 'approved');
  const isOnboardedSecurities = Boolean(securitiesOnboardingStatus === 'approved');
  const isOnboarded = isOnboardedCrypto || isOnboardedSecurities;

  useEffect(() => {
    // make sure this logic doesnt override the user's selection in selectProductsView
    if (selectedProducts.length === 0) {
      const products: Product[] = [];
      if ((isEnabled(['crypto']) && !isEnabled(['securities'])) || (isOnboardedSecurities && !isOnboardedCrypto)) {
        products.push('crypto');
      }
      if ((isEnabled(['securities']) && !isEnabled(['crypto'])) || (isOnboardedCrypto && !isOnboardedSecurities)) {
        products.push('security');
      }
      if (isEnabled(['crypto', 'securities'])) {
        if (cryptoOnboardingStatus === 'notStartedKyc' && !products.includes('crypto')) {
          products.push('crypto');
        }
        if (securitiesOnboardingStatus === 'notStartedKyc' && !products.includes('security')) {
          products.push('security');
        }
      }
      // prevent infinite loop
      if (products.length > 0) {
        setSelectedProducts(products);
      }
    }
  }, [
    hasAcceptedCryptoTerms,
    hasAcceptedSecuritiesTerms,
    selectedProducts,
    cryptoOnboardingStatus,
    securitiesOnboardingStatus,
    isEnabled,
    isOnboardedSecurities,
    isOnboardedCrypto,
  ]);

  const getSectionProgress = (step: number) => {
    if (step > currentStep) {
      return 0;
    }

    if (step === currentStep) {
      return 10;
    }

    if (step < currentStep || isOnboarded) {
      return 100;
    }
  };

  return {
    // TODO organize these into object groups
    currentOnboardingStatus,
    onboardingStatusCrypto: cryptoOnboardingStatus,
    onboardingStatusSecurities: securitiesOnboardingStatus,
    isOnboarded, // TODO: rename isOnboarded to isOnboardedAllProducts or remove isOnboarded
    isOnboardedCrypto,
    isOnboardedSecurities,
    hasNewTerms,
    selectedProducts,
    setSelectedProducts,
    currentStep,
    totalSteps: 2,
    getSectionProgress,
    // isSuccess: user.isSuccess, // TODO: add isSuccess with useState and implement setIsSuccess in useEffects
    // isLoading: user.isLoading, // TODO: add isLoading with useState and implement setIsLoading in useEffects
    // isError: user.isError, // TODO: add isError with useState and implement setIsError in useEffects
  };
};

export default useOnboardingInfo;
