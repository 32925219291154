import { Flex, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';

const Disclosure = ({ heading, children }: { heading: string; children: ReactNode }) => {
  return (
    <Flex
      gap="2"
      flexDirection="column"
      mb="4"
      sx={{
        a: {
          textDecoration: 'underline',
        },
      }}
    >
      <Heading as="h4" fontSize="2xs">
        {heading}
      </Heading>

      {children}
    </Flex>
  );
};

export default Disclosure;
