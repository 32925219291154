import { useQuery } from '@tanstack/react-query';

import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetUserStatement = ({ statementId, gcTime }: { statementId: string; gcTime: number }) => {
  const { apiClient } = useAxiosContext();
  const statementUrl = encodeURIComponent(statementId);

  return useQuery({
    gcTime,
    enabled: false,
    queryKey: [apiRoutes.statement(statementUrl), statementId, apiClient],
    queryFn: async () => {
      return apiClient!.get(apiRoutes.statement(statementUrl)).then((r) => r.data as string);
    },
  });
};

export default useGetUserStatement;
