'use client';

import { Box, CardFooter, Flex, FlexProps } from '@chakra-ui/react';
import { CfAreaChart, CfCard, formatUsd, getDelta, uiColors } from '@cryptofi/core-ui';
import { Dispatch, MouseEventHandler, SetStateAction, useRef, useState } from 'react';

import {
  TimeRangeSelector,
  // TrendIndicator
} from '~/components';
import { AllAssetIds, ChartTimeRange } from '~/customTypes';
import chartDateFormatter from '~/utils/chartDateFormatter';

import TotalValue from './TotalValue';

interface Props extends FlexProps {
  selectedTimeRange: ChartTimeRange;
  setSelectedTimeRange: Dispatch<SetStateAction<ChartTimeRange>>;
  chartData: any[];
  totalValue: number;
  isLoaded: boolean;
  valueType: 'portfolio' | 'asset';
  assetId?: AllAssetIds;
}

const PerformanceOverTime = ({
  selectedTimeRange,
  setSelectedTimeRange,
  chartData,
  totalValue,
  isLoaded,
  valueType,
  assetId,
  ...rest
}: Props) => {
  const [isHovering, setIsHovering] = useState(false); // necessary because the tooltip gets stuck open on hover
  const [hideBalance, setHideBalance] = useState(false);

  const { delta, isNetGain } = getDelta({ data: chartData, dataKey: 'value' });
  const formattedBalance = formatUsd({ amount: totalValue });

  const chartHeight = { base: '12rem', lg: '16rem' };
  const outerCardRef = useRef<HTMLDivElement>(null);
  const innerCardRef = useRef<HTMLDivElement>(null);

  const handleInnerCardVisibility: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!innerCardRef.current || !outerCardRef.current) {
      return;
    }

    // cursor position
    const cursorX = e.clientX;
    const cursorY = e.clientY;

    // inner card position and dimensions
    const boundingBox = innerCardRef.current.getBoundingClientRect();

    // check if the cursor is within the inner card's bounding box (or buffer zone)
    if (
      /* eslint-disable no-restricted-syntax */

      // add 60 pixels on each side to handle tooltip near a vertical edge
      cursorX + 60 > boundingBox.left &&
      cursorX < boundingBox.left + boundingBox.width + 60 &&
      cursorY > boundingBox.top &&
      cursorY < boundingBox.top + outerCardRef.current?.clientHeight - 20 // hide inner card when cursor's X coordinate is behind or below it
      /* eslint-enable no-restricted-syntax */
    ) {
      setHideBalance(true);
    } else {
      setHideBalance(false);
    }
  };

  return (
    <CfCard p="0" gap="0" {...rest}>
      <Box
        position="relative"
        height={chartHeight}
        zIndex="2" // keep tooltips from getting clipped by adjacent elements
      >
        <Box
          ref={outerCardRef}
          pt="3rem"
          position="absolute"
          zIndex={0}
          height={chartHeight}
          width="full"
          onMouseEnter={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
            setHideBalance(false); // handles edge cases that onMouseMove doesn't catch
          }}
          onMouseMove={handleInnerCardVisibility}
        >
          <CfAreaChart
            data={chartData}
            dataKey="value"
            height="inherit"
            showTooltip={isHovering}
            formatter={(amount) => String(formatUsd({ amount }))}
            labelFormatter={(_, chartProps) => {
              if (chartProps.length) {
                return chartDateFormatter({ date: chartProps[0].payload.date, selectedTimeRange });
              }
            }}
          />
        </Box>

        <TotalValue
          hideBalance={hideBalance}
          ref={innerCardRef}
          formattedBalance={formattedBalance}
          totalValue={totalValue}
          isNetGain={isNetGain}
          delta={delta}
          isLoaded={isLoaded}
          valueType={valueType}
          assetId={assetId}
        />

        <Flex
          justifyContent="flex-end"
          position="absolute"
          bottom="4"
          right={{ base: 0, lg: 4 }}
          left={{ base: 0, lg: 'auto' }}
          margin="auto"
          width="fit-content"
        >
          <TimeRangeSelector selectedTimeRange={selectedTimeRange} setSelectedTimeRange={setSelectedTimeRange} />
        </Flex>
      </Box>

      <CardFooter
        borderTop="solid 1px"
        borderColor={uiColors.coolElegance()}
        gap={{ base: 4, lg: 8 }}
        justifyContent={{ base: 'center', lg: 'flex-start' }}
      >
        {/* <TrendIndicator
          heading="Investments"
          isNetGain // TODO
          amount={investments}
          isLoading={isLoading}
          flexGrow={{ base: 1, lg: 0 }}
          justifyContent="center"
        />

        <TrendIndicator
          heading="Gains"
          isNetGain // TODO
          amount={gains}
          isLoading={isLoading}
          flexGrow={{ base: 1, lg: 0 }}
          justifyContent="center"
        /> */}
      </CardFooter>
    </CfCard>
  );
};

export default PerformanceOverTime;
