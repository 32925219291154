import Big from 'big.js';
import { Camelized } from 'humps';

import { AssetBalance, RedisSecurityInfo, TokenPriceResponse } from '~/codegen/types';
import { Investment } from '~/customTypes';

export const calculatePercentangeChange = ({
  initialValue,
  currentValue,
}: {
  initialValue?: number;
  currentValue?: number;
}) => {
  if (!initialValue || !currentValue || initialValue <= 0 || currentValue <= 0) {
    return 0;
  }
  const priceDifference = new Big(currentValue).minus(initialValue);
  const percentChange = priceDifference.div(initialValue).toNumber();
  return percentChange;
};

export function calculateTotalBalance(investments: Investment[]): number {
  return investments.reduce((acc, inv) => acc.plus(inv.amountUsd || 0), Big(0)).toNumber();
}

export function calculateSecurityInvestments({
  securityPrices,
  userSecurities,
}: {
  securityPrices: Camelized<RedisSecurityInfo>[];
  userSecurities: Camelized<Record<string, AssetBalance>>;
}) {
  const investments: Investment[] = [];

  securityPrices.forEach((security) => {
    const securityBalanceData = userSecurities?.[security.securitiesTicker || ''];
    if (security.securitiesTicker && securityBalanceData) {
      const amountOwned = Number(securityBalanceData.amountOwned || 0);
      if (amountOwned === 0) {
        return;
      }
      const currentPrice = Number(security.currentPrice?.value || 0);
      const avgCost = Number(securityBalanceData.avgCost || 0);
      const amountUsd = Big(currentPrice).times(amountOwned).toNumber();

      const percentChangeDaily = calculatePercentangeChange({
        initialValue: Number(security?.lastTradingDayInfo?.closePrice),
        currentValue: currentPrice,
      });

      const percentChangeAllTime = calculatePercentangeChange({
        initialValue: avgCost,
        currentValue: currentPrice,
      });

      const allTimeGainLoss = new Big(amountUsd).minus(new Big(avgCost).times(amountOwned)).toNumber();

      const dailyGainLoss = new Big(amountUsd).minus(new Big(avgCost).times(amountOwned)).toNumber();

      investments.push({
        assetId: security.securitiesTicker,
        assetName: security.name || undefined,
        currentPrice,
        avgCost,
        amountOwned,
        sparkline: undefined,
        delta: {
          allTime: {
            percentChange: percentChangeAllTime,
            isGain: percentChangeAllTime >= 0,
            totalGainLoss: allTimeGainLoss,
          },
          daily: {
            percentChange: percentChangeDaily,
            isGain: percentChangeDaily >= 0,
            totalGainLoss: dailyGainLoss,
          },
        },
        amountUsd,
      });
    }
  });

  return investments;
}

export function calculateCryptoInvestments(cryptoPrices: Camelized<TokenPriceResponse>[]) {
  const investments = cryptoPrices
    .filter((d) => d.amountOwned !== 0)
    .map((inv) => {
      const currentPrice = Number(inv.buyPrice);
      const percentChangeAllTime = calculatePercentangeChange({
        initialValue: inv.avgCost,
        currentValue: currentPrice,
      });
      const allTimeGainLoss = new Big(inv.amountUsd).minus(new Big(inv.avgCost).times(inv.amountOwned)).toNumber();

      const percentChangeDaily = calculatePercentangeChange({
        initialValue: Number(inv.sparkline?.[0]?.value || 0),
        currentValue: currentPrice,
      });
      const dailyGainLoss = new Big(inv.amountUsd).minus(new Big(inv.avgCost).times(inv.amountOwned)).toNumber();

      const investment: Investment = {
        assetId: inv.baseToken,
        assetName: inv.tokenName,
        currentPrice,
        avgCost: inv.avgCost,
        amountUsd: inv.amountUsd,
        amountOwned: inv.amountOwned,
        sparkline: inv.sparkline,
        delta: {
          allTime: {
            percentChange: percentChangeAllTime,
            isGain: percentChangeAllTime >= 0,
            totalGainLoss: allTimeGainLoss,
          },
          daily: {
            percentChange: percentChangeDaily,
            isGain: percentChangeDaily >= 0,
            totalGainLoss: dailyGainLoss,
          },
        },
      };

      return investment;
    });

  return investments;
}
