import { useFeatureSetEnabled, useGetMostSevereStatusDetails, useOnboardingInfo } from '~/hooks';

const useIsTradingDisabled = () => {
  const { isSystemDown } = useGetMostSevereStatusDetails();
  const { onboardingStatusCrypto, onboardingStatusSecurities } = useOnboardingInfo();
  const { isEnabled } = useFeatureSetEnabled();

  // TODO make disabling trading, product specific
  const isTradingDisabled =
    isSystemDown ||
    (isEnabled(['securities']) && onboardingStatusSecurities === 'rejected') ||
    (isEnabled(['crypto']) && onboardingStatusCrypto === 'rejected');

  return { isTradingDisabled };
};

export default useIsTradingDisabled;
