import { dateFormats, utcDateToLocal } from '@cryptofi/core-ui';
import { Camelized } from 'humps';

import { StatusResponse, SystemStatuses } from '~/codegen/types';
import { AllSystemsStatusProps } from '~/customTypes';
import { useGetSystemStatus } from '~/hooks';

// TODO revisit this
const useGetMostSevereStatusDetails = () => {
  const { data, refetch } = useGetSystemStatus();

  const currentHighestSevereSystemStatus = getMostSevereStatus(data);

  const isSystemDown = currentHighestSevereSystemStatus?.status === SystemStatuses.DOWN;

  const localStartTime = currentHighestSevereSystemStatus?.startTimeUtc
    ? utcDateToLocal({
        dateFormat: dateFormats.dateWithTime,
        timeString: currentHighestSevereSystemStatus?.startTimeUtc,
      })
    : null;

  const localEndTime = currentHighestSevereSystemStatus?.endTimeUtc
    ? utcDateToLocal({
        dateFormat: dateFormats.dateWithTime,
        timeString: currentHighestSevereSystemStatus?.endTimeUtc,
      })
    : null;

  const hasScheduledTime = Boolean(localStartTime && localEndTime);

  const statusMessage = currentHighestSevereSystemStatus?.message ? currentHighestSevereSystemStatus?.message : '';

  let systemStatusMessage = '';

  if (hasScheduledTime) {
    systemStatusMessage = `${statusMessage} ${localStartTime} - ${localEndTime}`;
  } else {
    systemStatusMessage = statusMessage;
  }

  return {
    isSystemDown,
    refetch,
    systemStatusMessage,
  };
};

const getMostSevereStatus = (allSystemsStatus: AllSystemsStatusProps | undefined) => {
  if (!allSystemsStatus) {
    return undefined;
  }

  let mostSevere: Camelized<StatusResponse> = allSystemsStatus.system;

  Object.values(allSystemsStatus).forEach((system) => {
    if (
      system.status === SystemStatuses.DOWN ||
      (system.status === SystemStatuses.WARNING && mostSevere.status !== SystemStatuses.DOWN)
    ) {
      mostSevere = system;
    }
  });

  return mostSevere;
};

export default useGetMostSevereStatusDetails;
