import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

import { useDebugInfo } from '~/hooks';

// TODO remove this
const useVisibilityChange = () => {
  const { getDebugInfo } = useDebugInfo();

  useEffect(() => {
    const onVisibilityChange = () => {
      mixpanel.track('Tab Visibility Changed', {
        Visibility: document.visibilityState,
        'Tab ID': getDebugInfo().tabId,
      });
    };

    window.addEventListener('visibilitychange', onVisibilityChange);

    // cleanup
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [getDebugInfo]);
};

export default useVisibilityChange;
