import { Box, Flex, Heading, Skeleton, Table, Tbody, Text, Tr } from '@chakra-ui/react';
import { formatUsd, uiColors } from '@cryptofi/core-ui';
import { useQueryClient } from '@tanstack/react-query';
import Big from 'big.js';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useFormContext } from 'react-hook-form';

import { OrderStatusEnum } from '~/codegen/types';
import { AssetLogo, LabelTd, ValueTd } from '~/components';
import { Transaction } from '~/customTypes';
import { useGetFees, useGetMostSevereStatusDetails, useGlobalStore, useTransactionHandlers } from '~/hooks';
import { apiRoutes } from '~/routes';
import { isCrypto, logError } from '~/utils';

import ConfirmTransactionButton from './ConfirmTransaction';
import { useModalContext } from './ModalContext';
import { TransactionFormValues } from './transactionSchema';

// convert buy amount to USD if necessary, if the user input 2 LTC for example
const getTransactionPrice = (currencyAmount: number, buyPrice: number, isUsd: boolean | undefined) => {
  if (isUsd) {
    return currencyAmount;
  }

  return Big(buyPrice).mul(currencyAmount).toNumber();
};

const ReviewTransaction = () => {
  const {
    bankAccounts,
    buyPrice,
    buyCurrencyAmount,
    sellPrice,
    sellCurrencyAmount,
    transactionType,
    setNetBuyQuantity,
    selectedAssetId,
  } = useModalContext();
  const isBuy = transactionType === 'buy';

  const { getValues } = useFormContext();
  const { buyAccountId, buyIsUsd, sellAccountId, sellIsUsd } = getValues() as TransactionFormValues;

  const fees = useGetFees({
    price: isBuy
      ? getTransactionPrice(buyCurrencyAmount, buyPrice, buyIsUsd)
      : getTransactionPrice(sellCurrencyAmount, sellPrice, sellIsUsd),
  });

  useEffect(() => {
    if (isBuy) {
      setNetBuyQuantity(
        Big(isCrypto(selectedAssetId) ? fees.data?.txNet || 0 : buyCurrencyAmount)
          .div(buyPrice)
          .toNumber(),
      );
    }
  }, [setNetBuyQuantity, isBuy, buyPrice, fees.data?.txNet, selectedAssetId, buyCurrencyAmount]);

  const selectedAccount = bankAccounts.find((account) => {
    const accountId = isBuy ? buyAccountId : sellAccountId;
    return account.accountId === accountId;
  });

  const { accountDescription, displayAccountNumber, availableBalance } = selectedAccount || {};

  // TODO error state
  const transactionFee = isCrypto(selectedAssetId) ? fees?.data?.txFee || 0 : 0;
  let transactionAmount = 0;
  if (isCrypto(selectedAssetId) && fees?.data?.txNet) {
    transactionAmount = Number(fees?.data?.txNet);
  }
  if (!isCrypto(selectedAssetId) && fees?.data?.txGross) {
    transactionAmount = Number(fees?.data?.txGross);
  }

  return (
    <Flex py="4">
      <Flex m="-6" p="6" h="100%" w="calc(100% + 4rem)" position="relative">
        <Table variant="unstyled">
          <Tbody>
            <Tr>
              <LabelTd>Asset price</LabelTd>

              <ValueTd>{formatUsd({ amount: isBuy ? buyPrice : sellPrice })}</ValueTd>
            </Tr>

            <Tr>
              <LabelTd>{isBuy ? 'Pay with' : 'Receive in'}</LabelTd>

              <ValueTd>
                <Flex position="relative" top={accountDescription ? 2.5 : 0}>
                  <Text
                    color={uiColors.sonicSilver()}
                    fontSize="sm"
                    position="absolute"
                    top="-5"
                    right="0"
                    fontFamily="body"
                    whiteSpace="nowrap"
                    bg={uiColors.lighthouse()}
                    pl="3"
                  >
                    {accountDescription}
                  </Text>

                  <Text>
                    {/* eslint-disable-next-line react/jsx-newline */}
                    **{displayAccountNumber} - {formatUsd({ amount: availableBalance || '' })}
                  </Text>
                </Flex>
              </ValueTd>
            </Tr>

            <Tr>
              <LabelTd>Amount</LabelTd>

              <ValueTd>
                <Skeleton isLoaded={!isCrypto(selectedAssetId) || Boolean(fees?.data)}>
                  {formatUsd({ amount: transactionAmount, precision: 2 })}
                </Skeleton>
              </ValueTd>
            </Tr>

            {transactionFee !== 0 && (
              <Tr>
                <LabelTd>
                  <Flex position="relative">
                    <Text>Transaction fee</Text>

                    {/* 
                NOTE: product asked to remove this from the UI, leaving this commented out for now as it is complicated to reimplement
                
                <Box
                  aria-label="More info about transaction fees"
                  position="absolute"
                  top="-0.5"
                  right="-10"
                  bg={uiColors.lighthouse()}
                  pr="3"
                >
                  <Box
                    cursor="pointer"
                    onClick={() => {
                      // TODO
                    }}
                  >
                    <IconHelp
                      display="inline-block"
                      __css={{ path: { fill: uiColors.sonicSilver() } }}
                      transform="scale(80%)"
                    />
                  </Box>
                </Box> */}
                  </Flex>
                </LabelTd>

                <ValueTd>
                  <Skeleton isLoaded={!isCrypto(selectedAssetId) || Boolean(fees?.data)}>
                    {formatUsd({ amount: transactionFee })}
                  </Skeleton>
                </ValueTd>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
};

export default ReviewTransaction;

// external sub components

const HeaderContent = () => {
  const { selectedAssetId, sellQuantity, transactionType, netBuyQuantity } = useModalContext();
  const isBuy = transactionType === 'buy';

  return (
    <Flex pt="8" pb="2" gap="4">
      <AssetLogo assetId={selectedAssetId!} showId showName logoSize="10" width="50%" />

      <Flex flexDirection="column" ml="auto" alignItems="flex-end" flexGrow={1}>
        <Heading as="h2" textAlign="right">
          <Text color={uiColors.sonicSilver()} fontSize="md">
            {isBuy ? 'Purchasing' : 'Selling'}
          </Text>

          {isBuy && (
            <Skeleton isLoaded={Boolean(netBuyQuantity)}>
              <Text fontSize="2xl">{`${netBuyQuantity.toFixed(8)} ${selectedAssetId}`}</Text>
            </Skeleton>
          )}

          {!isBuy && <Text fontSize="2xl">{`${sellQuantity.toFixed(8)} ${selectedAssetId}`}</Text>}
        </Heading>
      </Flex>

      <Box // Divisor line
        position="absolute"
        top={{ base: '9.5rem', md: '9rem' }}
        left="0"
        h="0"
        w="full"
        borderTop="solid 1px"
        borderColor={uiColors.coolElegance()}
      />
    </Flex>
  );
};
ReviewTransaction.HeaderContent = HeaderContent;

const FooterContent = () => {
  const [addClientOrderId] = useGlobalStore((state) => [state.addClientOrderId]);
  const {
    setModalView,
    buyPrice,
    buyCurrencyAmount,
    bankAccounts,
    selectedAssetId,
    buyCrypto,
    buySecurity,
    buyQuantity,
    transactionType,
    sellCurrencyAmount,
    sellPrice,
    sellCrypto,
    sellQuantity,
    sellSecurity,
    selectedSecurity,
    transactionState,
  } = useModalContext();
  const { isSystemDown } = useGetMostSevereStatusDetails();
  const queryClient = useQueryClient();
  const { buyAccountId, buyIsUsd, sellAccountId, sellIsUsd } = useFormContext().getValues() as TransactionFormValues;
  const isBuy = transactionType === 'buy';

  const fees = useGetFees({
    price: isBuy
      ? getTransactionPrice(buyCurrencyAmount, buyPrice, buyIsUsd)
      : getTransactionPrice(sellCurrencyAmount, sellPrice, sellIsUsd),
    enabled: isCrypto(selectedAssetId),
  });

  const { buyCryptoHandler, sellCryptoHandler, buySecurityHandler, sellSecurityHandler } = useTransactionHandlers();

  const bankAccount = bankAccounts.find((account) => {
    const accountId = isBuy ? buyAccountId : sellAccountId;

    return account.accountId === accountId;
  });

  const clearQueryCache = () => {
    // clear the bank accounts cache so that the user's available USD balance is updated
    // this should happen outside of the interval, as the bank accounts will be updated at this point
    // and these routes may have rate limiting restrictions based on the FI
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey.includes(apiRoutes.bankAccounts());
      },
    });
  };

  const confirmTransaction = () => {
    const assetIsCrypto = isCrypto(selectedAssetId);

    if (bankAccount?.accountType && selectedAssetId) {
      const args = {
        accountType: bankAccount.accountType,
        assetId: selectedAssetId,
        orderPrice: fees.data!.txGross,
        onSettled: (transaction: Transaction | undefined) => {
          // Store the client order id so that polling will be activated until it's copmleted
          // See useBalanceAndOrdersPolling hook for more docs
          if (transaction?.txId && transaction.status !== OrderStatusEnum.ERROR) {
            addClientOrderId(transaction.txId!);
          }
          setModalView('transactionResults');
          clearQueryCache();
        },
      };

      /// buy crypto
      if (isBuy && assetIsCrypto) {
        buyCryptoHandler({
          ...args,
          accountId: buyAccountId,
          assetId: selectedAssetId,
          mutateFn: buyCrypto,
          quantity: buyQuantity,
        });
      }

      // sell crypto
      if (!isBuy && assetIsCrypto) {
        sellCryptoHandler({
          ...args,
          accountId: sellAccountId,
          assetId: selectedAssetId,
          mutateFn: sellCrypto,
          quantity: sellQuantity,
        });
      }

      // buy security
      if (isBuy && !assetIsCrypto) {
        buySecurityHandler({
          ...args,
          accountId: buyAccountId,
          assetId: selectedAssetId,
          mutateFn: buySecurity,
          quantity: buyQuantity,
          securitiesIdentifier: selectedSecurity?.securitiesIdentifier!,
        });
      }

      // sell security
      if (!isBuy && !assetIsCrypto) {
        sellSecurityHandler({
          ...args,
          accountId: sellAccountId,
          assetId: selectedAssetId,
          mutateFn: sellSecurity,
          quantity: sellQuantity,
          securitiesIdentifier: selectedSecurity?.securitiesIdentifier!,
        });
      }
    }
  };

  return (
    <Box className="error-boundary" width="full">
      <ErrorBoundary
        onError={(error) => {
          logError({ error });
        }}
        fallback={
          <Flex>
            <Text fontSize="md" color={uiColors.heroicRed()}>
              Error: There was an error generating a preview of this transaction.
            </Text>
          </Flex>
        }
      >
        <ConfirmTransactionButton
          fees={fees}
          isLoading={Boolean(transactionState?.isPending)}
          transactionType={transactionType}
          isSystemDown={isSystemDown}
          bankAccount={bankAccount}
          confirmTransaction={confirmTransaction}
        />
      </ErrorBoundary>
    </Box>
  );
};
ReviewTransaction.FooterContent = FooterContent;
