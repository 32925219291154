/**
 * @description Store and retrieve debug information in session storage,
 * for use with logging tools like Sentry, Mixpanel, etc.
 */

import { nanoid } from 'nanoid';
import { useCallback } from 'react';
interface DebugInfo {
  tabId: string;
  fiId: string;
  userAccountId: string;
}

const useDebugInfo = () => {
  const setDebugInfo = useCallback(({ fiId, userAccountId }: Omit<DebugInfo, 'tabId'>) => {
    const stored = sessionStorage.getItem('debug');

    if (!stored) {
      sessionStorage.setItem(
        'debug',
        JSON.stringify({
          tabId: nanoid(), // set a unique id for each tab the user has open
          fiId: fiId,
          userAccountId,
        }),
      );
    }
  }, []);

  const getDebugInfo = useCallback((): DebugInfo => {
    const stored = sessionStorage.getItem('debug');

    return stored ? JSON.parse(stored) : {};
  }, []);

  return {
    setDebugInfo,
    getDebugInfo,
  };
};

export default useDebugInfo;
