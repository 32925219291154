/* eslint-disable no-console */
/* eslint-disable react/jsx-newline */
'use client';

import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  // eslint-disable-next-line no-restricted-imports
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  SimpleGrid,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
  Wrap,
} from '@chakra-ui/react';
import { formatPhoneNumber } from '@cryptofi/core-ui';
import dayjs from 'dayjs';
import { camelizeKeys } from 'humps';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';

import { KYCStatusEnum } from '~/codegen/types';
import { useFeatureSetEnabled, useGetFiInfo, useGetSystemStatus, useGetUser, useGlobalStore } from '~/hooks';
// eslint-disable-next-line no-restricted-imports
import useAxios from '~/hooks/api/useAxios';

import Button from './Button';
import ConsoleButton from './ConsoleButton';
import MenuItem from './MenuItem';

// TODO allow enabling via local storage
const DevConsole = ({
  setShowBreakpointDebugger,
}: {
  setShowBreakpointDebugger: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const fiInfo = useGetFiInfo();
  const user = useGetUser();
  const status = useGetSystemStatus();
  const statusData = camelizeKeys(status.data); // TODO move camelize to hook
  const axios = useAxios();
  const apiUrl = process.env.NEXT_PUBLIC_API_URL ? process.env.NEXT_PUBLIC_API_URL.replace(/\/$/, '') : '';
  const { isEnabled } = useFeatureSetEnabled();

  const [devInfo, setDevInfo] = useState<any | null>(null);
  const [enableRefetch, setEnableRefetch] = useState(true);
  const [outlinesEnabled, setOutlinesEnabled] = useState(false);
  const [errorBoundariesEnabled, setErrorBoundariesEnabled] = useState(false);
  const [isOpenReactQueryConsole, setIsOpenReactQueryConsole] = useState(false);

  // listen for ctrl + d to toggle dev console
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'd') {
        if (isOpen) {
          onClose();
        } else {
          onOpen();
        }
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen, onClose, onOpen]);

  // fetch version info from server route
  useEffect(() => {
    axios
      .get('/api/dev-info')
      .then(({ data }) => {
        setDevInfo(data);
      })
      .catch((error) => console.error('Error fetching version info:', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // check if refetch is disabled in local storage
    const enable = localStorage.getItem('enableRefetch');
    setEnableRefetch(!enable);

    // check if outlines are displayed
    if (document.body.classList.contains('show-outlines')) {
      setOutlinesEnabled(true);
    }

    // check if error boundaries are displayed
    if (document.body.classList.contains('show-error-boundaries')) {
      setErrorBoundariesEnabled(true);
    }

    // check if React Query console is open
    const queryContainer = document.querySelector('.tsqd-queries-container') as HTMLElement;
    setIsOpenReactQueryConsole(Boolean(queryContainer));
  }, []);

  const { hasCopied, onCopy } = useClipboard(userAuthInfo?.idToken || '');

  const fiRows = [
    { label: 'FI ID', value: user.data?.fiId },
    { label: 'Name', value: fiInfo.data?.fiName },
    {
      label: 'Crypto Enabled',
      value: (
        <Box as="span" fontFamily="body">
          {isEnabled(['crypto']) ? '✔' : '✘'}
        </Box>
      ),
    },
    {
      label: 'Securities Enabled',
      value: (
        <Box as="span" fontFamily="body">
          {isEnabled(['securities']) ? '✔' : '✘'}
        </Box>
      ),
    },
  ];

  const getUserName = () => {
    const { firstName, middleName, lastName } = user.data?.userInfo || {};
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
  };

  const userRows = [
    {
      label: 'Account ID',
      value: user.data?.userAccountId,
    },
    {
      label: 'Name',
      value: getUserName(),
    },
    {
      label: 'Email',
      value: user.data?.userInfo?.email,
    },
    {
      label: 'Phone',
      value: formatPhoneNumber(user.data?.userInfo?.phone || ''),
    },
    {
      label: 'Crypto KYC Status',
      value: user.data?.cryptoKycStatus,
    },
    {
      label: 'Securities KYC Status',
      value: user.data?.securitiesKycStatus,
    },
    {
      label: 'Securities Risk Profile',
      value: user.data?.securitiesRiskProfile,
    },
  ];

  const systemRows = [
    {
      label: 'Crypto Available',
      value: (
        <Tooltip label={enableRefetch ? null : 'Refetch disabled'} hasArrow>
          <Box width="fit-content" as="span">
            <Text as="span" color={enableRefetch ? 'inherit' : 'gray.600'}>
              {statusData?.isCryptoAvailable.status || '--'}
            </Text>
          </Box>
        </Tooltip>
      ),
    },
    {
      label: 'Securities Available',
      value: (
        <Tooltip label={enableRefetch ? null : 'Refetch disabled'} hasArrow>
          <Box width="fit-content" as="span">
            <Text as="span" color={enableRefetch ? 'inherit' : 'gray.600'}>
              {statusData?.isSecuritiesAvailable.status || '--'}
            </Text>
          </Box>
        </Tooltip>
      ),
    },
    {
      label: 'Securities Trading Open',
      value: (
        <Tooltip label={enableRefetch ? null : 'Refetch disabled'} hasArrow>
          <Box width="fit-content" as="span">
            <Text as="span" color={enableRefetch ? 'inherit' : 'gray.600'}>
              {statusData?.securitiesTradingOpen.status || '--'}
            </Text>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const devRows = [
    {
      label: 'Next.js Version',
      value: (
        <Flex as="span">
          <Tooltip label="Specified version" hasArrow>
            <Box as="span">{devInfo?.nextSpecifiedVersion || '--'}</Box>
          </Tooltip>
          &nbsp;/&nbsp;
          <Tooltip label="Installed version" hasArrow>
            <Box as="span">{devInfo?.nextInstalledVersion || '--'}</Box>
          </Tooltip>
        </Flex>
      ),
    },
    {
      label: 'React Version',
      value: (
        <Flex as="span">
          <Tooltip label="Specified version" hasArrow>
            <Box as="span">{devInfo?.reactSpecifiedVersion || '--'}</Box>
          </Tooltip>
          &nbsp;/&nbsp;
          <Tooltip label="Installed version" hasArrow>
            <Box as="span">{devInfo?.reactInstalledVersion || '--'}</Box>
          </Tooltip>
        </Flex>
      ),
    },
    {
      label: 'Core UI Version',
      value: (
        <Flex as="span">
          <Tooltip label="Specified version" hasArrow>
            <Box as="span">{devInfo?.coreSpecifiedVersion || '--'}</Box>
          </Tooltip>
          &nbsp;/&nbsp;
          <Tooltip label="Installed version" hasArrow>
            <Box as="span">{devInfo?.coreInstalledVersion || '--'}</Box>
          </Tooltip>
        </Flex>
      ),
    },
    {
      label: 'Node.js Version',
      value: (
        <Flex as="span">
          <Tooltip label="Specified version" hasArrow>
            <Box as="span">{devInfo?.nodeSpecifiedVersion || '--'}</Box>
          </Tooltip>
          &nbsp;/&nbsp;
          <Tooltip label="Installed version" hasArrow>
            <Box as="span">{devInfo?.nodeInstalledVersion || '--'}</Box>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const updateKyc = (kycState: KYCStatusEnum) => {
    axios
      .post(
        `${apiUrl}/v2/dev-tools/change-user-state`,
        {},
        {
          headers: {
            't-c-version': kycState === 'NOT_STARTED' ? 'none' : 'current',
            'kyc-state': kycState,
            'fi-id': user.data?.fiId,
            'user-account-id': user.data?.userAccountId,
          },
        },
      )
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        console.error('Error resetting onboarding');
      });
  };

  const toggleReactQueryConsole = () => {
    const queryContainer = document.querySelector('.tsqd-queries-container') as HTMLElement;

    if (queryContainer) {
      const el: HTMLElement = document.querySelector('.tsqd-minimize-btn') as HTMLElement;
      el?.click();
      setIsOpenReactQueryConsole(false);
    } else {
      const el: HTMLElement = document.querySelector('.tsqd-open-btn') as HTMLElement;
      el?.click();
      setIsOpenReactQueryConsole(true);
    }
  };

  const toggleRefetch = () => {
    if (enableRefetch) {
      localStorage.setItem('enableRefetch', 'false');
    } else {
      localStorage.removeItem('enableRefetch');
    }

    setEnableRefetch(!enableRefetch);

    window.location.reload();
  };

  const authExpiry = dayjs.unix(userAuthInfo?.expiryDate || 0).format('hh:mm a, MMM DD');
  const kycStates = Object.keys(KYCStatusEnum).sort();

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />

        <DrawerContent bg="blackAlpha.900" color="green.500" fontFamily="monospace" className="dev-console">
          <DrawerCloseButton />

          <DrawerHeader>
            <Flex gap="4" alignItems="center">
              <Heading as="h1" fontFamily="monospace" fontSize="2xl" p="0">
                Dev Console
              </Heading>

              {!enableRefetch && (
                <Text fontSize="10px" fontWeight="normal" color="gray.500">
                  Refetch disabled
                </Text>
              )}
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Flex gap={6} flexDir="column">
              <Flex flexDir="column">
                <Heading
                  as="h3"
                  fontSize="md"
                  borderBottom="solid 1px"
                  borderColor="green.700"
                  mb="2"
                  fontFamily="mono"
                >
                  Actions
                </Heading>

                <Wrap>
                  <Button onClick={onCopy}>
                    <>
                      {hasCopied && (
                        <span
                          style={{
                            position: 'absolute',
                            left: 0,
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          Copied
                        </span>
                      )}

                      <span style={{ visibility: hasCopied ? 'hidden' : 'visible' }}>Copy bearer token</span>
                    </>
                  </Button>

                  <Menu>
                    <MenuButton as={Button}>Change KYC state</MenuButton>
                    <MenuList bg="black" borderColor="green.500">
                      <MenuItem
                        kycState={'NOT_STARTED' as KYCStatusEnum}
                        onClick={() => {
                          updateKyc('NOT_STARTED' as KYCStatusEnum);
                        }}
                      />
                      <MenuItem
                        kycState={'APPROVED' as KYCStatusEnum}
                        onClick={() => {
                          updateKyc('APPROVED' as KYCStatusEnum);
                        }}
                      />

                      <Divider my="2" />

                      {kycStates
                        .filter((state) => state !== 'APPROVED' && state !== 'NOT_STARTED')
                        .map((state) => {
                          return (
                            <MenuItem
                              key={state}
                              kycState={state as KYCStatusEnum}
                              onClick={() => {
                                updateKyc(state as KYCStatusEnum);
                              }}
                            />
                          );
                        })}
                    </MenuList>
                  </Menu>

                  <Button onClick={toggleReactQueryConsole}>
                    {isOpenReactQueryConsole ? 'Hide' : 'Show'} React Query console
                  </Button>

                  <Button onClick={toggleRefetch}>{enableRefetch ? 'Disable' : 'Enable'} refetch intervals</Button>

                  <Button
                    onClick={() => {
                      // TODO named handlers
                      onClose();
                      setShowBreakpointDebugger(true);
                    }}
                  >
                    Show breakpoint debugger
                  </Button>

                  <Button
                    onClick={() => {
                      setOutlinesEnabled(!outlinesEnabled);
                      document.body.classList.toggle('show-outlines');
                    }}
                  >
                    {outlinesEnabled ? 'Hide' : 'Show'} outlines
                  </Button>

                  <Button
                    onClick={() => {
                      setErrorBoundariesEnabled(!errorBoundariesEnabled);
                      document.body.classList.toggle('show-error-boundaries');
                    }}
                  >
                    {errorBoundariesEnabled ? 'Hide' : 'Show'} error boundaries
                  </Button>
                </Wrap>
              </Flex>

              <Flex flexDir="column">
                <Heading
                  as="h3"
                  fontSize="md"
                  borderBottom="solid 1px"
                  borderColor="green.700"
                  mb="2"
                  fontFamily="mono"
                >
                  User Info
                </Heading>

                <SimpleGrid columns={2} gap={1}>
                  {userRows.map((row) => (
                    <Fragment key={row.label}>
                      <Text textTransform="uppercase" fontSize="xs">
                        {row.label}
                      </Text>

                      <Text>{row.value}</Text>
                    </Fragment>
                  ))}
                </SimpleGrid>
              </Flex>

              <Flex flexDir="column">
                <Heading
                  as="h3"
                  fontSize="md"
                  borderBottom="solid 1px"
                  borderColor="green.700"
                  mb="2"
                  fontFamily="mono"
                >
                  FI Info
                </Heading>

                <SimpleGrid columns={2} gap={1}>
                  {fiRows.map((row) => (
                    <Fragment key={row.label}>
                      <Text textTransform="uppercase" fontSize="xs">
                        {row.label}
                      </Text>

                      <Text>{row.value}</Text>
                    </Fragment>
                  ))}
                </SimpleGrid>
              </Flex>

              <Flex flexDir="column">
                <Heading
                  as="h3"
                  fontSize="md"
                  borderBottom="solid 1px"
                  borderColor="green.700"
                  mb="2"
                  fontFamily="mono"
                >
                  System Info
                </Heading>

                <SimpleGrid columns={2} gap={1}>
                  {systemRows.map((row) => (
                    <Fragment key={row.label}>
                      <Text textTransform="uppercase" fontSize="xs">
                        {row.label}
                      </Text>

                      <Text>{row.value}</Text>
                    </Fragment>
                  ))}
                </SimpleGrid>
              </Flex>

              <Flex flexDir="column">
                <Heading
                  as="h3"
                  fontSize="md"
                  borderBottom="solid 1px"
                  borderColor="green.700"
                  mb="2"
                  fontFamily="mono"
                >
                  Version Info
                </Heading>

                <SimpleGrid columns={2} gap={1}>
                  {devRows.map((row) => (
                    <Fragment key={row.label}>
                      <Text textTransform="uppercase" fontSize="xs">
                        {row.label}
                      </Text>

                      <Text>{row.value}</Text>
                    </Fragment>
                  ))}
                </SimpleGrid>
              </Flex>

              <Flex flexDir="column">
                <Heading
                  as="h3"
                  fontSize="md"
                  borderBottom="solid 1px"
                  borderColor="green.700"
                  mb="2"
                  fontFamily="mono"
                >
                  Links
                </Heading>

                <List listStyleType="disc" listStylePosition="inside">
                  <ListItem>
                    <Link
                      href="https://api.cryptofi-dev.com/v2/docs"
                      target="api-docs"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      CWS API Documentation
                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link
                      href="https://cryptofi-core-ui.vercel.app/?path=/docs/intro--docs"
                      target="storybook"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      Core UI Storybook
                    </Link>
                  </ListItem>
                </List>
              </Flex>
            </Flex>
          </DrawerBody>

          <DrawerFooter justifyContent="space-between">
            <Text fontSize="10px" fontWeight="normal">
              Local branch: {devInfo?.branchName || '--'}
            </Text>

            <Text fontSize="10px">Auth expires at: {authExpiry}</Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <ConsoleButton isOpen={isOpen} enableRefetch={enableRefetch} onOpen={onOpen} />
    </>
  );
};

export default DevConsole;
