import { Alert, Box, Text } from '@chakra-ui/react';
import { IconInfo, uiColors } from '@cryptofi/core-ui';

const KycPendingAlert = () => {
  return (
    <Alert colorScheme="yellow" mt="4">
      <Box>
        <IconInfo
          mr="6"
          __css={{
            path: { fill: 'yellow.500' },
          }}
        />
      </Box>

      <Text color={uiColors.black()}>
        Your application is pending further review. Please reach out to support for further assistance.
      </Text>
    </Alert>
  );
};

export default KycPendingAlert;
