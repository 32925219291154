import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { IconAlert, IconCheck, uiColors } from '@cryptofi/core-ui';

import { useModalContext } from './ModalContext';

const TransactionResults = () => {
  const { selectedAssetId, sellQuantity, transactionType, netBuyQuantity, transactionState, transactionStatusIsError } =
    useModalContext();
  const isBuy = transactionType === 'buy';

  return (
    <Flex py="4">
      <Flex alignItems="center" justifyContent="center" w="full" minHeight="14rem" flexDirection="column">
        {transactionState?.isSuccess && !transactionStatusIsError && (
          <>
            <Box bg={uiColors.mint()} padding="3" borderRadius="full">
              <IconCheck
                __css={{
                  path: { fill: 'white' },
                }}
              />
            </Box>

            <Heading as="h2" textAlign="center" mt="8">
              <Text fontSize="md" color={uiColors.sonicSilver()}>
                {isBuy ? 'Purchased' : 'Sold'}
              </Text>

              <Text fontSize="2xl">
                {isBuy && `${netBuyQuantity.toFixed(8)} ${selectedAssetId}`}

                {!isBuy && `${sellQuantity.toFixed(8)} ${selectedAssetId}`}
              </Text>
            </Heading>
          </>
        )}

        {transactionState?.isError ||
          (transactionStatusIsError && (
            <>
              <Box bg={uiColors.heroicRed()} padding="3" borderRadius="full">
                <IconAlert
                  __css={{
                    path: { fill: 'white' },
                  }}
                />
              </Box>

              <Heading as="h2" textAlign="center" mt="8" fontFamily="body">
                <Text fontSize="md" color={uiColors.sonicSilver()}>
                  There was an error with your transaction. Please try again later.
                </Text>
              </Heading>
            </>
          ))}
      </Flex>
    </Flex>
  );
};

export default TransactionResults;

// external sub components

const FooterContent = () => {
  const { setModalView } = useModalContext();

  return (
    <Button
      width="full"
      variant="outline"
      onClick={() => {
        setModalView('startTransaction');
      }}
    >
      Try again
    </Button>
  );
};
TransactionResults.FooterContent = FooterContent;
