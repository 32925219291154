'use client';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, useMemo } from 'react';

import { defaultStaleTime } from '~/constants';
import { useBrandTheme, useMixpanel, useVisibilityChange } from '~/hooks';
import { appTheme } from '~/theme';

import AuthProvider from './AuthProvider';
import { AxiosProvider } from './AxiosProvider';
import { DataProvider } from './DataProvider';
import { StoreProvider } from './StoreProvider';

/**
 * @description Suppress the warning about missing defaultProps in Recharts.
 * @link https://github.com/recharts/recharts/issues/3615
 *
 */
/* eslint-disable no-console */
const error = console.error;
console.error = (...args: any) => {
  // TODO use isDev function when available
  if (/defaultProps/.test(args[0]) && process.env.NODE_ENV === 'development') {
    return;
  }
  error(...args);
};
/* eslint-enable no-console */

const Providers = ({ children }: { children: React.ReactElement }) => {
  const client = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: defaultStaleTime,
          },
        },
      }),
    [],
  );

  useMixpanel();
  useVisibilityChange();

  // extend the default Chakra theme with the app theme and FI brand colors
  const { brandTheme } = useBrandTheme();
  const theme = extendTheme(appTheme({ brand: brandTheme }));

  return (
    <QueryClientProvider client={client}>
      <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-right' } }}>
        {/* todo: build fallback skeleton */}
        <Suspense fallback={<></>}>
          <AxiosProvider>
            <StoreProvider>
              <AuthProvider>
                <DataProvider>{children}</DataProvider>
              </AuthProvider>
            </StoreProvider>
          </AxiosProvider>
        </Suspense>
      </ChakraProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default Providers;
