'use client';

import { Link } from '@chakra-ui/next-js';
import { ChakraComponent, Divider, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { IconArrowUp, IconChecking, IconInfo, IconOverview, IconSettlement, uiColors } from '@cryptofi/core-ui';
import { usePathname } from 'next/navigation';
import { Fragment } from 'react';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { OnboardingModal } from '~/components';
import { useFeatureSetEnabled, useOnboardingInfo, usePostTelemetryEvent } from '~/hooks';
import { uiRoutes } from '~/routes';
import { uiConstants } from '~/theme';

interface Item {
  text: string;
  IconComponent: ChakraComponent<'svg', object>;
  isActive: boolean;
  userCanAccess: boolean;
  blackAndWhiteIcon?: boolean;
  addSeparator?: boolean;
  href?: string;
  isHidden?: boolean;
  handleClick?: () => void;
}

const NavItems = () => {
  const { trackEvent } = usePostTelemetryEvent();
  const path = usePathname();
  const { isEnabled } = useFeatureSetEnabled();
  const { isOnboarded, isOnboardedSecurities, isOnboardedCrypto } = useOnboardingInfo();
  const {
    isOpen: isOnboardingModalOpen,
    onOpen: onOnboardingModalOpen,
    onClose: onOnboardingModalClose,
  } = useDisclosure();

  const showOnboardingLinkItem =
    (isEnabled(['crypto']) &&
      isEnabled(['securities']) &&
      isOnboarded &&
      !(isOnboardedSecurities && isOnboardedCrypto)) ||
    isOnboardingModalOpen; //for showing the the completed view of the onboarding modal

  const linkItems: Item[] = [
    {
      text: !isOnboardedSecurities ? 'Set up securities account' : 'Set up crypto account',
      IconComponent: IconArrowUp,
      blackAndWhiteIcon: true,
      isActive: false,
      isHidden: !showOnboardingLinkItem,
      addSeparator: showOnboardingLinkItem,
      userCanAccess: true,
      handleClick: () => {
        onOnboardingModalOpen();
      },
    },
    {
      text: 'Home',
      IconComponent: IconOverview,
      href: uiRoutes.root(),
      isActive: path === uiRoutes.root() || path.includes('/assets'),
      userCanAccess: true,
      handleClick: () => trackEvent(TelemetryClientSideEventsEnum.ClickedMainNavCryptoHomeClient),
    },
    {
      text: 'Transactions',
      IconComponent: IconSettlement,
      href: uiRoutes.transactionHistory(),
      isActive: path.includes(uiRoutes.transactionHistory()),
      userCanAccess: isOnboarded,
      handleClick: () => trackEvent(TelemetryClientSideEventsEnum.ClickedMainNavTransactionHistoryClient),
    },
    {
      text: 'Learn',
      IconComponent: IconInfo,
      href: uiRoutes.learn(),
      isActive: path.includes(uiRoutes.learn()),
      userCanAccess: true,
      handleClick: () => trackEvent(TelemetryClientSideEventsEnum.ClickedMainNavLearnClient),
    },
    {
      text: 'Documents',
      IconComponent: IconChecking,
      href: uiRoutes.documents(),
      isActive: path.includes(uiRoutes.documents()),
      userCanAccess: isOnboarded,
    },
  ];

  const getIconCss = (item: Item) => {
    let fillColor;
    if (item.blackAndWhiteIcon) {
      fillColor = '#fff';
    } else if (item.isActive) {
      fillColor = 'brand.500';
    } else {
      fillColor = uiColors.sonicSilver();
    }
    return { path: { fill: fillColor } };
  };

  return (
    <>
      {linkItems
        .filter((item) => !item.isHidden)
        .map((item) => {
          const {
            text,
            IconComponent,
            blackAndWhiteIcon,
            isActive,
            href = window.location.href,
            handleClick = () => {},
            addSeparator = false,
          } = item;
          return (
            <Fragment key={href}>
              <Flex
                as={Link}
                alignItems="center"
                variant={'outline'}
                gap="2"
                w={{ base: undefined, lg: '3rem' }}
                h={{ base: undefined, lg: '3rem' }}
                onClick={handleClick}
                borderRadius="md"
                border="solid 1px"
                bg={isActive ? 'brand.50' : 'transparent'}
                borderColor={isActive ? 'brand.200' : 'transparent'}
                boxShadow={isActive ? uiConstants.boxShadow : 'none'}
                href={href}
                lineHeight="1"
                position="relative"
                padding="2"
                zIndex="3" // keep nav tooltip above charts
                transition={`border-color ${uiConstants.transitionDuration}`}
                _hover={{
                  borderColor: !isActive && uiColors.coolElegance(),
                  textDecoration: 'none',
                  '.tooltip': {
                    display: {
                      lg: 'block',
                    },
                  },
                }}
              >
                <Flex
                  h="full"
                  w={{ base: undefined, lg: 'full' }}
                  alignItems="center"
                  justifyContent={{ base: 'flex-start', lg: 'center' }}
                >
                  <IconComponent
                    h="5"
                    w="5"
                    p={blackAndWhiteIcon ? '1' : '0'}
                    borderRadius="md"
                    bg={blackAndWhiteIcon ? 'black' : 'transparent'}
                    __css={getIconCss(item)}
                  />
                </Flex>

                {/* inline text is displayed at smaller breakpoints when the drawer is open */}
                <Text
                  display={{
                    lg: 'none',
                  }}
                  fontFamily="heading"
                  color={isActive ? 'brand.500' : uiColors.sonicSilver()}
                >
                  {text}
                </Text>

                {/* tooltip is displayed on hover at larger breakpoints */}
                <Text
                  className="tooltip"
                  fontFamily="heading"
                  display="none"
                  position="absolute"
                  left="3.75rem"
                  bg="white"
                  padding="2"
                  border="solid 1px"
                  boxShadow={uiConstants.boxShadow}
                  borderColor={uiColors.coolElegance()}
                  borderRadius="md"
                  fontSize="small"
                  whiteSpace="nowrap"
                  _before={{
                    content: '""',
                    bg: 'white',
                    width: '0.5rem',
                    height: '0.5rem',
                    position: 'absolute',
                    left: '-.4rem',
                    top: '.6rem',
                    border: 'solid 1px',
                    borderColor: uiColors.coolElegance(),
                    borderRight: 'none',
                    borderTop: 'none',
                    transform: 'rotate(45deg)',
                  }}
                >
                  {text}
                </Text>
              </Flex>

              {addSeparator && <Divider />}
            </Fragment>
          );
        })}

      {showOnboardingLinkItem && (
        <>
          {/* onboarding modal needs to live here so that it does not render early which creates bad modal state. */}
          <OnboardingModal isOpen={isOnboardingModalOpen} onClose={onOnboardingModalClose} />
        </>
      )}
    </>
  );
};

export default NavItems;
