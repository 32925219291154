import { Button, Divider, Flex, Heading, ListItem, SimpleGrid, Text, UnorderedList } from '@chakra-ui/react';
import { IconBank, IconCaretRight } from '@cryptofi/core-ui';
import { upperCase } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

import { introContentsSecurities } from '~/constants';
import { OnboardingModalViews } from '~/customTypes';
import { useGetFiInfo } from '~/hooks';

const WelcomeSecuritiesView = ({ setModalView }: { setModalView: Dispatch<SetStateAction<OnboardingModalViews>> }) => {
  const fiInfo = useGetFiInfo();

  return (
    <Flex direction="column" gap={4}>
      <Flex alignItems="center" gap={4}>
        <IconBank __css={{ path: { fill: 'brand.500' } }} />

        <Flex direction="column" gap={2} textAlign="left">
          <Heading as="h3" size="xs">
            {fiInfo.data?.fiName}
          </Heading>

          <Text fontSize="sm">{upperCase(fiInfo.data?.fiType)}</Text>
        </Flex>
      </Flex>

      <Heading as="h2" size="xl" color="brand.500">
        Welcome to Investments
      </Heading>

      <Text>Get started with traditional investments: Trade Stocks & ETFs</Text>

      <UnorderedList mt="2" color="brand.500" w="full" spacing={2}>
        {introContentsSecurities.map(({ description }, i) => {
          return (
            <ListItem ml={{ base: 0, md: 2 }} w="full" key={i} fontFamily="heading">
              {description}
            </ListItem>
          );
        })}
      </UnorderedList>

      <Divider my="2" />

      <Flex w="full" mb="2">
        <Button
          width={{ base: 'full', md: 'auto' }}
          ml={{ md: 'auto' }}
          rightIcon={<IconCaretRight />}
          onClick={() => {
            setModalView('termsAndConditions');
          }}
        >
          Get started
        </Button>
      </Flex>
    </Flex>
  );
};

export default WelcomeSecuritiesView;
