'use client';

import { Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { CfCard, CfSpinner, dateFormats, formatUsd, utcDateToLocal } from '@cryptofi/core-ui';
import { UseQueryResult } from '@tanstack/react-query';
import { Camelized } from 'humps';
import { capitalize, size } from 'lodash';

import { SearchOrderPaginator } from '~/codegen/types';
import { AssetLogo, TransactionStatus } from '~/components';

interface TransactionHistoryTable {
  transactions: UseQueryResult<Camelized<SearchOrderPaginator>, Error>;
}

const TransactionHistoryTable = ({ transactions }: TransactionHistoryTable) => {
  const { data, isLoading } = transactions;
  const hasData = Boolean(size(data?.items));

  if (isLoading) {
    return (
      <CfCard>
        <CfSpinner />
      </CfCard>
    );
  }

  return (
    <Flex direction="column" gap={4} w="full">
      <CfCard align="center">
        <TableContainer w="full">
          {!hasData && (
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td textAlign="center">No data</Td>
                </Tr>
              </Tbody>
            </Table>
          )}

          {hasData && (
            <Table variant="simple" fontSize="sm">
              <Thead>
                <Tr>
                  <Th py="3" px="4">
                    Asset
                  </Th>

                  <Th py="3" px="4" w="8rem" textAlign="right">
                    ID
                  </Th>

                  <Th py="3" px="4" textAlign="right">
                    Date
                  </Th>

                  <Th py="3" px="4" textAlign="right">
                    Amount
                  </Th>

                  <Th py="3" px="4" textAlign="center" width="8rem">
                    Type
                  </Th>

                  <Th py="3" px="4" textAlign="right" width="10rem">
                    Price
                  </Th>

                  <Th py="3" px="4" textAlign="right">
                    Cost
                  </Th>

                  <Th py="3" px="4" textAlign="right">
                    Fee
                  </Th>

                  <Th py="3" px="4" textAlign="center">
                    Status
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {data?.items?.map((transaction) => (
                  <Tr key={transaction.txId}>
                    <Td py="3" px="4">
                      <Flex alignItems="center" gap={4}>
                        <AssetLogo assetId={transaction.asset} />

                        {transaction.asset}
                      </Flex>
                    </Td>

                    <Td py="3" px="4" fontFamily="mono" textAlign="right">
                      {transaction.txId}
                    </Td>

                    <Td py="3" px="4" fontFamily="mono" textAlign="right">
                      {utcDateToLocal({ dateFormat: dateFormats.dateOnly, timeString: transaction.transactTime })}
                    </Td>

                    <Td py="3" px="4" fontFamily="mono" textAlign="right">
                      {Number(transaction.txQuantity).toFixed(8)}
                    </Td>

                    <Td py="3" px="4" textAlign="center">
                      {capitalize(transaction.orderSide)}
                    </Td>

                    <Td py="3" px="4" fontFamily="mono" textAlign="right">
                      {transaction.assetPrice &&
                        formatUsd({
                          amount: Number(transaction.assetPrice),
                        })}
                    </Td>

                    <Td py="3" px="4" fontFamily="mono" textAlign="right">
                      {formatUsd({
                        amount: Number(transaction.txGross),
                      })}
                    </Td>

                    <Td py="3" px="4" fontFamily="mono" textAlign="right">
                      {formatUsd({
                        amount: Number(transaction.txFee),
                      })}
                    </Td>

                    <Td py="3" px="4" textAlign="center">
                      <TransactionStatus transaction={transaction} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </CfCard>
    </Flex>
  );
};

export default TransactionHistoryTable;
