import Big from 'big.js';
import { size } from 'lodash';
import { useMemo } from 'react';

import { securitiesRefetchInterval } from '~/constants';
import { useFeatureSetEnabled, useGetSecurities, useGetTokenPrices, useGetUser } from '~/hooks';
import { calculateCryptoInvestments, calculateSecurityInvestments, calculateTotalBalance } from '~/utils/investments';

const useUserInvestments = () => {
  const { isEnabled } = useFeatureSetEnabled();
  const user = useGetUser();

  const tickers = Object.keys(user.data?.balance?.securities || {}).join(',');
  const securityPrices = useGetSecurities({ tickers, refetchInterval: securitiesRefetchInterval });
  const cryptoPrices = useGetTokenPrices();

  const securityInvestments = useMemo(() => {
    if (securityPrices.data && user.data?.balance?.securities) {
      const investments = calculateSecurityInvestments({
        securityPrices: securityPrices.data,
        userSecurities: user?.data.balance.securities,
      });
      return investments;
    }
    return [];
  }, [securityPrices.data, user.data?.balance?.securities]);

  const cryptoInvestments = useMemo(() => {
    const investments = calculateCryptoInvestments(cryptoPrices.data || []);
    return investments;
  }, [cryptoPrices.data]);

  const totalBalances = useMemo(() => {
    const totalSecuritiesBalance = calculateTotalBalance(securityInvestments);
    const totalCryptoBalance = calculateTotalBalance(cryptoInvestments);
    return {
      securitiesInvestments: totalSecuritiesBalance,
      cryptoInvestments: totalCryptoBalance,
      allInvestments: Big(totalCryptoBalance).plus(totalSecuritiesBalance).toNumber(),
    };
  }, [securityInvestments, cryptoInvestments]);

  const isSuccessSecurities = isEnabled(['securities']) ? securityPrices.isSuccess : true;
  const isLoadingSecurities = isEnabled(['securities']) ? securityPrices.isLoading : false;

  return {
    isSuccess: isSuccessSecurities,
    isLoading: isLoadingSecurities,
    isError: securityPrices.isError || cryptoPrices.isError,
    data: {
      securityInvestments,
      cryptoInvestments,
      totalBalances,
      // eslint-disable-next-line no-restricted-syntax
      investmentCount: size(securityInvestments) + size(cryptoInvestments),
    },
  };
};

export default useUserInvestments;
