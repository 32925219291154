import { Button, Flex } from '@chakra-ui/react';
import { CfButtonGroup, CfButtonGroupButton } from '@cryptofi/core-ui';
import { useFormContext } from 'react-hook-form';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { AssetUnavailable, SystemStatusMessage } from '~/components';
import { TransactionTypes } from '~/customTypes';
import { useCanUserPurchaseSecurity, useGetMostSevereStatusDetails, useGetUser, usePostTelemetryEvent } from '~/hooks';
import { isCrypto } from '~/utils';

import BuyForm from './BuyForm';
import { useModalContext } from './ModalContext';
import SellForm from './SellForm';
import { isFormValid } from './utils';

const StartTransaction = ({ isLoading }: { isLoading: boolean }) => {
  const { transactionType } = useModalContext();

  return (
    <Flex py="4" width="100%">
      {transactionType === 'buy' && <BuyForm isLoading={isLoading} />}

      {transactionType !== 'buy' && <SellForm isLoading={isLoading} />}
    </Flex>
  );
};
export default StartTransaction;

// external sub components

const HeaderContent = () => {
  const { transactionType, setTransactionType, selectedAssetId, selectedSecurity } = useModalContext();
  const { setValue } = useFormContext();
  const { trackEvent } = usePostTelemetryEvent();
  const user = useGetUser();
  const canUserPurchaseSecurity = useCanUserPurchaseSecurity(selectedSecurity);

  const westreet = '23177';
  const isUnavailableDisabled = user.data?.fiId === westreet && selectedAssetId === 'XLM'; // TODO remove when sFox supports XLM
  const isUnavailableRiskTolerance = !canUserPurchaseSecurity && !isCrypto(selectedAssetId);

  const handleOnClick = (option: TransactionTypes) => {
    setTransactionType(option);
    setValue('transactionType', option);

    if (option === 'buy') {
      trackEvent(TelemetryClientSideEventsEnum.ClickedAssetBuyButtonClient, { asset: selectedAssetId });
    } else if (option === 'sell') {
      trackEvent(TelemetryClientSideEventsEnum.ClickedAssetSellButtonClient, { asset: selectedAssetId });
    }
  };

  const buttonProps = (option: TransactionTypes) => {
    return {
      onClick: () => handleOnClick(option),
      isSelected: transactionType === option,
      minWidth: '16',
      paddingX: '4',
    };
  };

  return (
    <>
      <Flex justifyContent="center" mt="6">
        <CfButtonGroup>
          <CfButtonGroupButton {...buttonProps('buy')}>Buy</CfButtonGroupButton>

          <CfButtonGroupButton {...buttonProps('sell')}>Sell</CfButtonGroupButton>
        </CfButtonGroup>
      </Flex>

      {(isUnavailableDisabled || isUnavailableRiskTolerance) && (
        <AssetUnavailable
          reason={isUnavailableRiskTolerance ? 'riskTolerance' : 'disabled'}
          showWeStreetContact={user.data?.fiId === westreet}
        />
      )}
    </>
  );
};
StartTransaction.HeaderContent = HeaderContent;

const FooterContent = () => {
  const { getFieldState, getValues } = useFormContext();
  const { transactionType, selectedAssetId, selectedSecurity } = useModalContext();
  const { trackEvent } = usePostTelemetryEvent();
  const { isSystemDown } = useGetMostSevereStatusDetails();
  const user = useGetUser();

  const handlePreviewTransactionClick = () => {
    const eventType =
      transactionType === 'buy'
        ? TelemetryClientSideEventsEnum.ClickedAssetPreviewBuyButtonClient
        : TelemetryClientSideEventsEnum.ClickedAssetPreviewSellButtonClient;

    trackEvent(eventType, { asset: selectedAssetId });
  };

  const canUserPurchaseSecurity = useCanUserPurchaseSecurity(selectedSecurity);

  const westreet = '23177';
  const isUnavailableDisabled = user.data?.fiId === westreet && selectedAssetId === 'XLM'; // TODO remove when sFox supports XLM
  // Disable the preview button if it is a buy transaction for a security and the user's risk profile is too low
  const isUnavailableRiskTolerance =
    !canUserPurchaseSecurity && transactionType === 'buy' && !isCrypto(selectedAssetId);

  return (
    <Flex direction="column" w="full">
      <SystemStatusMessage textAlign="center" />

      <Button
        // each form gets a unique id to keep both onsubmit events from firing
        form={transactionType === 'buy' ? 'buyForm' : 'sellForm'}
        isDisabled={
          !isFormValid({ getValues, getFieldState, transactionType }) ||
          isSystemDown ||
          isUnavailableDisabled ||
          isUnavailableRiskTolerance
        }
        type="submit"
        w="full"
        variant="outline"
        onClick={handlePreviewTransactionClick}
      >
        Preview
      </Button>
    </Flex>
  );
};
StartTransaction.FooterContent = FooterContent;
