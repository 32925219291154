/* eslint-disable react/jsx-newline */
'use client';

// eslint-disable-next-line no-restricted-imports
import { Alert, Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { IconInfo, uiColors } from '@cryptofi/core-ui';

const AssetUnavailable = ({
  reason,
  showWeStreetContact,
}: {
  reason: 'disabled' | 'riskTolerance';
  showWeStreetContact?: boolean;
}) => {
  return (
    <Alert borderColor={uiColors.coolElegance()} colorScheme="whiteAlpha" mt="4">
      <Box>
        <IconInfo
          mr="6"
          __css={{
            path: { fill: uiColors.coolElegance() },
          }}
        />
      </Box>

      <Flex direction="column" color={uiColors.black()} fontSize="sm" gap="2">
        <Heading as="h3" color={uiColors.black()} fontWeight="1200" fontSize="md" mb="1">
          Unable to invest in this asset
        </Heading>

        <Text>
          {reason === 'riskTolerance' &&
            `Based on your selected risk tolerance, this asset is not available for trading. Your investment options are
          tailored to match your risk profile for a more secure investing experience.`}

          {reason === 'disabled' &&
            `This asset is currently unavailable to trade. If you currently have holdings of this asset don’t worry, they
          are safe and secure. Trading will be available again soon!`}
        </Text>

        {/* TODO remove temp content for WeStreet */}
        {showWeStreetContact && (
          <Text>
            If you would like to liquidate your position in the meantime, please contact our Member Care Center at{' '}
            <Link whiteSpace="nowrap" fontFamily="heading" href="tel:9186100200">
              (918) 610-0200
            </Link>
            .
          </Text>
        )}
      </Flex>
    </Alert>
  );
};

export default AssetUnavailable;
