import { useQuery } from '@tanstack/react-query';
import { Camelized } from 'humps';

import { statusRefetchInterval } from '~/constants';
import { AllSystemsStatusProps } from '~/customTypes';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';

const useGetSystemStatus = () => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';

  return useQuery({
    refetchInterval: disableRefetch ? false : statusRefetchInterval,
    enabled: Boolean(userAuthInfo),
    queryKey: [apiRoutes.systemStatus(), apiClient],
    queryFn: async () => {
      return apiClient!.get(apiRoutes.systemStatus()).then((r) => r.data as Camelized<AllSystemsStatusProps>);
    },
  });
};

export default useGetSystemStatus;
