import { UseMutationResult } from '@tanstack/react-query';

import { TelemetryClientSideEventsEnum } from '~/codegen/types';
import { AllAssetIds, Transaction } from '~/customTypes';
import { usePostTelemetryEvent } from '~/hooks';

interface HandleTransaction {
  accountId: string;
  accountType: string;
  assetId: AllAssetIds;
  orderPrice: string;
  mutateFn: UseMutationResult<Transaction | undefined, unknown, unknown, unknown>;
  onSettled: (order: Transaction | undefined) => void;
  quantity: number | string;
}

interface HandleSecurityTransaction extends HandleTransaction {
  securitiesIdentifier: string;
}

const useTransactionHandlers = () => {
  const { trackEvent } = usePostTelemetryEvent();

  const buyCryptoHandler = ({
    assetId,
    accountId,
    accountType,
    orderPrice,
    onSettled,
    mutateFn,
    quantity,
  }: HandleTransaction) => {
    mutateFn.mutate(
      {
        accountNumber: accountId,
        accountType,
        currency: 'USD',
        netOrderQuantity: String(quantity),
        orderPrice,
        orderSide: 'BUY',
        token: assetId,
      },
      {
        onSettled,
      },
    );

    trackEvent(TelemetryClientSideEventsEnum.ClickedAssetConfirmBuyButtonClient, { asset: assetId });
  };

  const sellCryptoHandler = ({
    accountId,
    accountType,
    assetId,
    orderPrice,
    onSettled,
    mutateFn,
    quantity,
  }: HandleTransaction) => {
    mutateFn.mutate(
      {
        accountNumber: accountId,
        accountType,
        currency: 'USD',
        orderQuantity: String(quantity),
        orderPrice,
        orderSide: 'SELL',
        token: assetId,
      },
      {
        onSettled,
      },
    );

    trackEvent(TelemetryClientSideEventsEnum.ClickedAssetConfirmSellButtonClient, { asset: assetId });
  };

  const buySecurityHandler = ({
    assetId,
    accountId,
    accountType,
    orderPrice,
    onSettled,
    mutateFn,
    quantity,
    securitiesIdentifier,
  }: HandleSecurityTransaction) => {
    mutateFn.mutate(
      {
        accountNumber: accountId,
        accountType,
        currency: 'USD',
        netOrderQuantity: String(quantity),
        orderPrice,
        orderSide: 'BUY',
        securitiesIdentifier,
        securitiesTicker: assetId,
        securitiesType: 'EQUITY',
      },
      {
        onSettled,
      },
    );

    trackEvent(TelemetryClientSideEventsEnum.ClickedAssetConfirmBuyButtonClient, { asset: assetId });
  };

  const sellSecurityHandler = ({
    assetId,
    accountId,
    accountType,
    orderPrice,
    onSettled,
    mutateFn,
    quantity,
    securitiesIdentifier,
  }: HandleSecurityTransaction) => {
    mutateFn.mutate(
      {
        accountNumber: accountId,
        accountType,
        currency: 'USD',
        netOrderPrice: orderPrice,
        orderQuantity: String(quantity),
        orderSide: 'SELL',
        securitiesIdentifier,
        securitiesTicker: assetId,
        securitiesType: 'EQUITY',
      },
      {
        onSettled,
      },
    );

    trackEvent(TelemetryClientSideEventsEnum.ClickedAssetConfirmSellButtonClient, { asset: assetId });
  };

  return {
    buyCryptoHandler,
    sellCryptoHandler,
    buySecurityHandler,
    sellSecurityHandler,
  };
};

export default useTransactionHandlers;
