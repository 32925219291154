import { useQuery } from '@tanstack/react-query';
import { Camelized, camelizeKeys, decamelizeKeys } from 'humps';

import { SearchOrderPaginator } from '~/codegen/types';
import { defaultRecordsPerPage } from '~/constants';
import { TxHistoryFormData } from '~/customTypes';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { ignoreUpperCaseKeys } from '~/utils';

interface GetUserOrdersSearchProps {
  params?: TxHistoryFormData;
  recordsPerPage?: number;
}

const useGetUserOrdersSearch = ({ params, recordsPerPage = defaultRecordsPerPage }: GetUserOrdersSearchProps) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo),
    queryKey: [apiRoutes.searchOrders(), params, recordsPerPage, apiClient],
    queryFn: async () => {
      return apiClient!
        .get(apiRoutes.searchOrders(), {
          params: decamelizeKeys({
            scanIndexForward: false, // This sorts in descending order
            perPage: recordsPerPage,
            ...params,
          }),
        })
        .then((r) => camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as Camelized<SearchOrderPaginator>);
    },
  });
};

export default useGetUserOrdersSearch;
